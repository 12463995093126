import { NuxtError } from '@nuxt/types'
import { AxiosError } from 'axios'

export const GetNuxtErrorFromAxiosError = (error: AxiosError | any): NuxtError & { data?: string } => {
  let statusCode =
    error.statusCode ||
    error.response?.status ||
    error.code ||
    500

  if (statusCode === 'ECONNABORTED') {
    statusCode = 504
  }

  if (error.message?.includes('with status code ')) {
    statusCode = parseInt(error.message?.split('with status code ').pop(), 10)
  }

  const message =
    error.response?.data?.errors?.[0]?.message ||
    error.response?.data?.error?.message ||
    error.response?.data?.message ||
    error.response?.data?.error ||
    error.response?.data?.error_description ||
    error.response?.statusText ||
    error.response?.message ||
    error.message ||
    'MSG UNDEFINED'

  const nuxtErr: NuxtError & { data?: any } = { statusCode, message }

  // only pass data instead of whole axiosError because
  // can cause "maximum call stack size exceeded".
  if (error.response?.data) {
    nuxtErr.data = error.response.data
  }

  return nuxtErr
}

/**
 * Convert Axios Error To Nuxt Error
 * @param error
 * @returns
 */
export const ConvertAxiosErrorToNuxtError = function (error: any) {
  const { statusCode, message } = GetNuxtErrorFromAxiosError(error)
  error.statusCode = statusCode
  error.message = message
  return error
}

<template>
  <div>
    <div v-if="error" id="Layout-Error">
      <main class="Error">
        <Error500 v-if="isError500" />
        <ErrorDefault v-else :code="code" />
      </main>
    </div>
  </div>
</template>

<script>
import Error500 from '@/components/layouts/errors/Error500.vue'
import ErrorDefault from '@/components/layouts/errors/ErrorDefault.vue'

export default {
  name: 'LayoutError',
  components: {
    Error500,
    ErrorDefault
  },
  middleware: ['secure-iframe'],
  props: {
    error: {
      type: Object,
      validator: prop => !!prop.statusCode
    }
  },
  head() {
    return {
      title: `${this.$t(`error.code.${this.code}.title`)} | HelloAsso`
    }
  },
  computed: {
    code() {
      return this.error.statusCode?.toString() || 'unknown'
    },
    isError500() {
      return this.code === '500'
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  min-height: 100vh;
  padding-top: var(--header-height);
}
</style>

<style lang="scss">
.Error {
  display: flex;
  flex-direction: column;
  background-color: var(--ha-color-white);

  @include mediaQuery(900) {
    flex-direction: row;
  }

  &--Wrapper {
    position: relative;
    width: 100%;
    padding-top: $ha-spacing-giant;

    @include mediaQuery(900) {
      padding-top: $ha-spacing-giant * 2;
      padding-bottom: $ha-spacing-giant * 2;
    }
  }

  &--Title {
    margin-bottom: $ha-spacing-large;
    font-size: $ha-font-size-giant * 1.5; // 48px
    line-height: $ha-line-height-small;
  }

  &--Subtitle {
    margin-bottom: $ha-spacing-big;
    color: var(--ha-color-text-light);
  }

  &--Code {
    color: var(--ha-color-text-lightest);
    font-weight: $ha-font-weight-semibold;
  }

  &--Image {
    width: 100%;
    height: 100vw;
    margin-top: $ha-spacing-giant;
    background: url('~assets/images/illustrations/illustration-abyss.svg')
      no-repeat center left;
    background-size: cover;
    transform: rotate(90deg);

    @include mediaQuery(900) {
      position: absolute;
      top: 0;
      right: 0;
      width: 50%;
      height: 100%;
      margin-top: 0;
      transform: rotate(0);
    }
  }

  &--Buttons {
    margin-right: -($ha-spacing-tiny);
    margin-left: -($ha-spacing-tiny);

    a,
    button {
      margin: $ha-spacing-tiny;
    }
  }

  p {
    margin-bottom: $ha-spacing-medium;
    line-height: $ha-line-height-large;

    &:last-of-type {
      margin-bottom: $ha-spacing-big;
    }
  }
}

.error-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  color: gray;
  text-align: center;
}
</style>

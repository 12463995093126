import { computed, type Ref } from 'vue'
import { useStore } from '@nuxtjs/composition-api'
import { enum as enums } from '@ha/helpers'
import { useSsrState } from '@ha/components'
import useStoreData from '@/composables/useStoreData'
import useRouteParams from '@/composables/useRouteParams'

const formState: Ref<{
  isDescriptionCollapsed: boolean
  isLongDescription: boolean
}> = useSsrState('formState', () => ({
  isDescriptionCollapsed: true,
  isLongDescription: false,
}))

export default () => {
  const { form } = useStoreData()
  const { formType } = useRouteParams()
  const store = useStore()

  const isCrowdfunding = computed(() => form.value?.formType === enums.FormType.CROWDFUNDING)
  const isEvent = computed(() => form.value?.formType === enums.FormType.EVENT)
  const isMembership = computed(() => form.value?.formType === enums.FormType.MEMBERSHIP)
  const isShop = computed(() => form.value?.formType === enums.FormType.SHOP)
  const isSale = computed(() => form.value?.formType === enums.FormType.PAYMENTFORM)
  const isDonation = computed(() => form.value?.formType === enums.FormType.DONATION)
  // there is no form - and so no form.formType - for checkout bc no campaignSlug, however there is a formType from route params
  const isCheckout = computed(
    () => !form.value?.formType && formType.value === enums.FormType.CHECKOUT
  )

  const isWidget = store.getters['forms/getIsWidget']

  return {
    isCrowdfunding,
    isEvent,
    isMembership,
    isShop,
    isSale,
    isDonation,
    isCheckout,
    formState,
    isWidget
  }
}

import { datadogRum } from '@datadog/browser-rum'
import { functions } from '@ha/helpers'

export default ({
  $config: {
    DD_RUM_APP_ID,
    DD_RUM_CLIENT_TOKEN,
    DD_SITE,
    DD_SERVICE,
    DD_ENV,
    DD_RUM_SESSION_SAMPLE_RATE,
    DD_RUM_SESSION_REPLAY_SAMPLE_RATE
  },
  env: { DD_NUXT_ENV_TIMESTAMP }
}) => {
  const isDatadogActivated = DD_RUM_APP_ID
  if (isDatadogActivated) {
    datadogRum.init({
      applicationId: DD_RUM_APP_ID,
      clientToken: DD_RUM_CLIENT_TOKEN,
      site: DD_SITE,
      service: DD_SERVICE,
      env: DD_ENV,
      sessionSampleRate: DD_RUM_SESSION_SAMPLE_RATE,
      sessionReplaySampleRate: DD_RUM_SESSION_REPLAY_SAMPLE_RATE,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      version: DD_NUXT_ENV_TIMESTAMP,
      beforeSend: (event) => {
        if (event.error) {
          const error = event.error
          // filter out error that might be caused by link validation/ pre-fetch rendering from email clicks
          // https://github.com/DataDog/browser-sdk/issues/2715
          const regex = /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/
          if (regex.test(error.message)) return false
        }

        return true
      }
    })

    const colorScheme = functions.getPreferredColorScheme()

    if (colorScheme) {
      datadogRum.setUserProperty('theme-preference', colorScheme)
    }

    datadogRum.startSessionReplayRecording()
  }
}

<template>
  <div id="Layout-Default">
    <!-- A11y & notifs -->
    <ha-accessibility
      context="Forms"
      close-id="Forms_Modal_Accessibility_Close"
      :is-open="showAccessibilitySettings"
      @close="closeAccessibilitySettings"
    />
    <ha-toaster ref="haToaster" />
    <!-- Header -->
    <client-only>
      <HaHeaderPublic :class="{'relative' : isStepForm}" @connect-request="openLoginModal" @disconnect="disconnect" />
    </client-only>
    <!-- Content -->
    <nuxt :class="[{ AdminView: isFormAdmin }]" />
    <AdminSettings v-if="isFormAdmin" />
    <!-- Footer -->
    <LayoutFooter />

  </div>
</template>

<script>
import { provide } from 'vue'
import { HaAccessibility, HaToaster, HaHeaderPublic, useHeaderPublic } from '@ha/components'
import { accessTokenCookieName } from '@/constants'
import LayoutFooter from '@/components/layouts/LayoutFooter.vue'
import AdminSettings from '@/components/layouts/AdminSettings.vue'
import useNotifications from '@/composables/useNotifications'
import useFormType from '@/composables/useFormType'
import useLogin from '@/composables/useLogin'
import useTracking from '@/composables/useTracking'

export default {
  name: 'LayoutDefault',
  components: {
    HaAccessibility,
    LayoutFooter,
    HaToaster,
    AdminSettings,
    HaHeaderPublic,
  },
  middleware: ['secure-iframe'],
  setup() {
    const { haToaster } = useNotifications()
    const { isCrowdfunding, isEvent, isShop, isMembership } = useFormType()
    const pushToast = (options, error) => {
      haToaster.value.push(options)
      if (error) {
        console.error(error)
      }
    }
    provide('$notifications', { push: pushToast })
    provide('isWidget', false)

    const { onLogout } = useLogin()
    const { tracking } = useTracking()
    const { setUser, setOrganizations } = useHeaderPublic()

    return { haToaster, onLogout, tracking, setUser, setOrganizations, isCrowdfunding, isEvent, isShop, isMembership }
  },
  data() {
    return {
      showAccessibilitySettings: false,
    }
  },
  // We should always try to fetch the User & his Orgs on boot to properly display the Login status in the header
  computed: {
    form() {
      return this.$store.getters['forms/getForm'](this.metadata)
    },
    isFormAdmin() {
      return this.form?.hasAdminRights
    },
    isStepForm() {
      return this.isCrowdfunding || this.isEvent || this.isShop || this.isMembership
    }
  },
  created() {
    const token = this.$cookies.get(accessTokenCookieName)
    if (!token) {
      this.$store.commit('user/REVOKE_USER')
    }

    const { setHeader } = useHeaderPublic()
    setHeader(this.$router, this.tracking)
  },
  methods: {
    openAccessibilitySettings() {
      this.showAccessibilitySettings = true
    },
    closeAccessibilitySettings() {
      this.showAccessibilitySettings = false
    },
    async disconnect() {
      await this.onLogout()
    },
    openLoginModal() {
      window.location.href = `${this.$config.NUXT_ENV_AUTH_FRONT}/connexion?mode=contributor&back=${encodeURIComponent(window.location.href)}`
    }
  }
}
</script>

<style lang="scss" scoped>
main {
  min-height: 100vh;
  padding-top: var(--header-height);
}
</style>

<style lang="scss">
:root {
  /* Mint to Cobalt */
  --ha-color-primary: hsl(245, 60%, 53%);
  --ha-color-primary-h: 245;
  --ha-color-primary-s: 60%;
  --ha-color-primary-l: 53%;

  /* Dream to Mint */
  --ha-color-secondary: hsl(148, 61%, 56%);
  --ha-color-secondary-h: 148;
  --ha-color-secondary-s: 61%;
  --ha-color-secondary-l: 56%;

  /* Dream */
  --ha-color-tertiary: hsl(220, 88%, 80%);
  --ha-color-tertiary-h: 220;
  --ha-color-tertiary-s: 88%;
  --ha-color-tertiary-l: 80%;
}
</style>

import { DateTimeMeta } from '~/types/common.interface'
import { ExtraOption, ExtraOptionPlayload } from '@/components/extra-options/extraOptions.interface'
import { CustomField, CustomFieldPayload } from '@/components/custom-fields/customField.interface'
import { Tier } from '../tiers/tiers.interface'
import { Payer } from '../payers/payer.interface'
import { TipSuggestions } from '../voluntary-contributions/volontaryContribution.interface'

export interface CartPaymentTerms {
  date: string
  discount: number
  initialAmount: number
  taxReceiptEligible: number
  vat: number
  realAmount: number,
  realAmountWithTip: number;
}

export interface CartItemBase {
  customAmount?: number
  email?: string
  firstname?: string
  id?: number
  isEligibleTaxReceipt?: boolean
  paymentFrequency?: string | 'Monthly' | 'Installment'
  price?: number
  tierId: number
  tierType: string
  vatRate?: number
  participantLocalId?: string,
  paymentTerms?: Array<CartPaymentTerms>
  lastname?: string
}
export interface CartItem extends CartItemBase {
  customFields?: CustomField[]
  extraOptions?: ExtraOption[]
}

export interface CartItemPayload extends CartItemBase {
  extraOptions?: ExtraOptionPlayload[]
  customFields?: CustomFieldPayload[]
}

// Refactor - redundant mapping
export interface CartItemAltered extends CartItem {
  participantLocalId: string
}

export interface CartUpsertPayload {
  organizationSlug: string
  formSlug: string
  formType: string
  items: Array<CartItemPayload>
}

export interface CartBase {
  id: string
  formSlug: string
  organizationSlug: string
  containsDonation: boolean
  tierList: Array<Tier>
  paymentTerms: Array<CartPaymentTerms>
  payer?: Payer
  meta: DateTimeMeta
  totalAmount: number
}

export interface PaymentFrequency {
  frequency: number,
  paymentTerms: CartPaymentTerms[]
}

export interface Cart extends CartBase {
  itemList: Array<CartItem>
  tipData?: TipSuggestions
  availableFrequencies?: PaymentFrequency[]
}

export enum PaymentFrequencyOnePage {
  SINGLE = "SINGLE",
  INSTALLMENT = "INSTALLMENT",
  SUBSCRIPTION = "SUBSCRIPTION",
}

export interface CartUpsertResponse extends CartBase {
  itemList: Array<CartItemPayload>
}

export type CartId = string
export type Coupon = Record<CartId, string>

export enum CartsAction {
  SET_CART = 'SET_CART',
  REMOVE_CART = 'REMOVE_CART',
  PUT_TIP = 'PUT_TIP',
  SET_COUPON = 'SET_COUPON',
  UNSET_COUPON = 'UNSET_COUPON',
  SET_PAIEMENT_STATUS = 'SET_PAIEMENT_STATUS',
  SET_PAIEMENT_ERROR_CODE = 'SET_PAIEMENT_ERROR_CODE',
  SET_TIP = 'SET_TIP',
  UNSET_TIP = 'UNSET_TIP',
  SET_CART_META = 'SET_CART_META',
  UPDATE_VALUE = 'UPDATE_VALUE',
  SET_TIP_DATA = 'SET_TIP_DATA'
}

export enum CartsGetters {
  SELECTED_EXTRA_OPTIONS_BY_CART_ITEM = 'SELECTED_EXTRA_OPTIONS_BY_CART_ITEM',
  SELECTED_EXTRA_OPTIONS = 'SELECTED_EXTRA_OPTIONS'
}

export interface CartUpdateValueArgs {
  organizationSlug: string
  formType: string
  formSlug: string
  cartItemIndex?: number
  extraOptionIndex?: number
  customFieldIndex?: number
  key: keyof Cart | keyof CartItem | keyof ExtraOption | keyof CustomField
  value: string | number | boolean
}

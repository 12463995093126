/* eslint-disable no-underscore-dangle */
import { extend } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { messages } from 'vee-validate/dist/locale/fr.json'
import { required, email } from 'vee-validate/dist/rules'

import { size, toNumber, max } from 'lodash-es'
import { haMoment } from '@ha/helpers'

export default function ({ app }) {
  // import default rules from vee-validate
  Object.keys(rules).forEach(rule => {
    extend(rule, {
      ...rules[rule], // copies rule configuration
      message: messages[rule] // assign message
    })
  })

  /**
   * Extend base validators with customs
   */
  extend('REQUIRED', {
    ...required,
    message: app.i18n.t('validators.required')
  })
  extend('REQUIRED_AMOUNT', {
    ...required,
    message: app.i18n.t('validators.requiredAmount')
  })

  extend('EMAIL', {
    ...email,
    message: app.i18n.t('validators.email')
  })

  extend('PHONE', {
    message: app.i18n.t('validators.phone'),
    validate: value => {
      return /^([\d.+ ]{8,15})$/.test(value)
    }
  })

  extend('DATE', {
    message: app.i18n.t('validators.date'),
    validate(date) {
      if (!date) {
        return false
      }

      return haMoment(date, 'DD/MM/YYYY', true).isValid()
    }
  })

  extend('NAME_NO_NUMBERS', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameNoNumbers', [fieldName])
    },
    validate(value) {
      return /^(\D*)$/.test(value)
    }
  })

  extend('NAME_LENGTH', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameLength', [fieldName])
    },
    validate: value => {
      return size(value) > 1
    }
  })

  extend('NAME_CHARS', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameChars', [fieldName])
    },
    validate: value => {
      return !/[<>.!@#$%^&*/()"_+=;]/.test(value)
    }
  })

  extend('COUNTRY', {
    validate: value => {
      // only allow fr organizations
      return value === 'FR'
    }
  })

  extend('IS_TRUE', {
    message: app.i18n.t('validators.optionIsMandatory'),
    validate: value => {
      return value === true
    }
  })

  extend('ZIP_CODE', {
    message: app.i18n.t('validators.zipCode'),
    validate: value => /^[a-zA-Z0-9]{3,10}$/.test(value)
  })

  extend('MIN_AMOUNT', {
    params: ['minPrice'],
    message: (value, { minPrice }) => {
      const price = max([0.5, parseFloat(minPrice)])
      const priceFormatted = Number.isInteger(price) ? price : price.toFixed(2)
      return app.i18n.t('validators.minAmount', [priceFormatted])
    },
    validate: (value, { minPrice }) => {
      if (minPrice > 0) {
        return toNumber(value) >= max([0.5, minPrice])
      }
      return toNumber(value) === 0 || toNumber(value) >= 0.5
    }
  })

  extend('MAX_AMOUNT', {
    params: ['maxPrice'],
    message: (value, { maxPrice }) => {
      return app.i18n.t('validators.maxAmount', [maxPrice])
    },
    validate: (value, { maxPrice }) => {
      if (!maxPrice) {
        return true
      }
      const maxPriceInt = toNumber(maxPrice)
      return value < maxPriceInt
    }
  })

  extend('POSITIVE', {
    validate: value => {
      if (!value) return true
      return parseFloat(value) > 0
    },
    message: app.i18n.t('validators.notPositive')
  })

  extend('NAME_IS_FORBIDDEN', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameIsForbidden', [fieldName])
    },
    validate(value) {
      return !/^test$|^nom$|^prénom$|^prenom$|^user$|^admin$|^lastname$|^firstname$|^name$/.test(
        value.toLowerCase()
      )
    }
  })

  extend('NAME_DIFFERENT', {
    message: app.i18n.t('validators.nameDifferent'),
    validate: (value, [otherName]) => {
      return value.toLowerCase() !== (otherName || '').toLowerCase()
    }
  })

  extend ('EMAIL_MATCH', {
    message: app.i18n.t('validators.emailMatch'),
    validate: (value, [otherEmail]) => {
      return value === otherEmail
    }
  })

  extend('NAME_VOWELS', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameVowels', [fieldName])
    },
    validate: value => {
      return /[aeiouyéèêëàâäîïôöûüù]/.test(value.toLowerCase())
    }
  })

  extend('NAME_CONSECUTIVE_CHARS', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameConsecutiveChars', [fieldName])
    },
    validate: value => {
      return !/(.)\1{2,}/.test(value.toLowerCase())
    }
  })

  extend('NAME_CONTAINS_FORBIDDEN', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameContainsForbidden', [fieldName])
    },
    validate: value => {
      return !/^.*firstname.*|^.*lastname.*|^.*unknown.*|^.*first_name.*|^.*last_name.*|^.*anonyme.*/.test(
        value.toLowerCase()
      )
    }
  })

  extend('NAME_LATIN', {
    message(field) {
      const fieldName = field === 'firstName' ? 'prénom' : 'nom'
      return app.i18n.t('validators.nameLatin', [fieldName])
    },
    validate: value => {
      // eslint-disable-next-line no-control-regex
      return /^[a-zA-ZÀ-ÿŸ\s,.;'\-_():!/"[\]?0-9#£€$*%‘’“”«»…]*$/.test(value)
    }
  })

  extend('SIREN', {
    message() {
      return app.i18n.t('validators.siren')
    },
    validate: value => {
      return /^[0-9]{9}$/.test(value.replaceAll(' ', ''))
    }
  })

  extend('max_length', {
    params: ['maxSize'],
    validate: (value, { maxSize }) => {
      return value?.length <= maxSize
    },
    message: (value, { maxSize }) => {
      return app.i18n.t('validators.maxLength', [maxSize])
    }
  })

  extend('birthdate', {
    validate: value => {
      if (!value) return true

      const date = haMoment(value, 'DD/MM/YYYY').format()
      const minDate = haMoment('01/01/1900', 'DD/MM/YYYY')
      const maxDate = haMoment()

      return haMoment(date).isSameOrAfter(minDate) && haMoment(date).isSameOrBefore(maxDate)
    },
    message: app.i18n.t('validators.birthdate')
  })

  extend('legal_age', {
    validate: value => {
      if (!value || !haMoment(value, 'DD/MM/YYYY').isValid()) return true
      return haMoment(undefined, 'DD/MM/YYYY').diff(haMoment(value, 'DD/MM/YYYY'), 'year') >= 18
    },
    message: app.i18n.t('validators.legalAge')
  })
}

import tiers from '@/locales/tiers/index.es'
import globals from '@/locales/globals/index.es'

export default {
  tiers,
  globals,
  forms: {
    boutiquesParticipantInformations: "Copiar la información ingresada para todos los compradores siguientes",
    evenementsCopyParticipantInformations: "Copiar la información ingresada para todos los participantes siguientes",
    adhesionsCopyParticipantInformations: "Copiar la información ingresada para todos los participantes siguientes",
    requiredField: "Este campo es obligatorio"
  },
  administration: {
    admin: "Administradores",
    share: "mi página",
    shareShort: "Compartir",
    stats: "Estadísticas",
    update: "los contenidos",
    updateShort: "Modificar"
  },
  button: {
    choose: "Elegir",
    add: "Agregar",
    back: "Regreso",
    cancel: "Cancelar",
    copy: "Copiar",
    remove: "Retirar",
    save: "Registrar",
    update: "Modificar",
    send: "Enviar"
  },
  campaign: {
    currencyUnit: "{0} €",
    projectExpensesDetails: "Descripción de los puestos de gastos",
    projectBeneficiaries: "Beneficiarios finales de la recopilación",
    projectOwners: "Portadores del proyecto",
    projectTargetCountry: "Localización del proyecto",
    fundedAt: "Financiado al <b>{0}%</b> - <b>{1}€</b> / {2}€",
    goal: "Objetivo: {0} €",
    aboutOrganism: "Acerca de la asociación",
    contribute: "Contribuir al proyecto",
    saleEndDate: "Fin en",
    saleStartDate: "Comienzo en",
    MembershipMembers: "Adherente | Adherente | Adherentes",
    CrowdFundingMembers: "Contribuyente | Contribuyente | Contribuyentes",
    EventMembers: "Participante | Participante | Participantes",
    amountCollected: "recogido | recogido | recogidos",
    membershipFrom: "Adhesión válida",
    customTierPrice: "Cantidad libre",
    myPersonalInformations: "Mis datos de contacto",
    donation: "Don",
    selection: "Selección",
    myDonation: "Mi don",
    mySummary: "Mi resumen",
    monthlyTotal: "Total mensual",
    total: "Total",
    amountTtc: "Importe IVA incluido:",
    amountTva: "IVA incluido:",
    IGiveMonthly: "Doy todos los meses",
    IGiveOneTime: "Doy una vez",
    priceAfterRfReduction: "Su don solo le costará <b>{0}€</b> después de la reducción fiscal",
    fiscalReduction: "Reducción Fiscal",
    fiscalReductionRuban: "Fiscalidad",
    fiscalReductionDescription: "La donación a <b>{0}</b> da derecho a una reducción fiscal porque cumple con las condiciones generales previstas en los artículos 200 y 238 bis del código general de impuestos.",
    personalInformationsIsPerson: "Particular",
    personalInformationsIsCompany: "Organismo",
    logoOf: "Logo de la asociación {0}",
    seePaymentSchedule: "Ver el detalle",
    hidePaymentSchedule: "Ocultar el detalle",
    date: {
      noDate: {
        with2Times: "De {0} a {1}"
      },
      SameDay: {
        with2Times: "El {0}, de {1} a {2}",
        withEndTime: "El {0}, hasta el {1}",
        withNoTime: "El {0}",
        withStartTime: "El {0}, a partir de {1}"
      },
      with2Dates: {
        with2Times: "Del {0} al {1}, de {2} a {3}",
        withEndTime: "Del {0} al {1}, hasta {2}",
        withNoTime: "Del {0} al {1}",
        withStartTime: "Del {0} al {1}, a partir de {2}"
      }
    },
    error: {
      missingInfo: "Información faltante",
      emptyCart: "Necesitamos que su pedido contenga al menos un artículo.",
      fillMandatory: "Por favor, complete los campos obligatorios.",
      fileIsUploading: "Su archivo se está enviando.",
      pleaseWaiting: "Gracias por esperar."
    },
    field: {
      yourEmailAddress: "Su dirección de correo electrónico",
      address: "Dirección",
      birthday: "Fecha de nacimiento",
      city: "Ciudad",
      copyPayer: "Copiar la información ingresada para todos los siguientes participantes",
      country: "País",
      email: "Correo electrónico",
      emailButton: "Modificar su correo electrónico",
      emailConfirmation: "Correo de confirmación",
      file: "Haga clic aquí para importar un archivo",
      firstName: "Nombre",
      lastName: "Nombre",
      organism: "Denominación social",
      payer: "Este participante va a realizar el pago",
      zipcode: "Código postal",
      companySiren: "SIREN",
      companyLegalStatusId: "Forma jurídica",
      paymentDate: {
        nextPayment: "Para validar su tarjeta bancaria, su primer cargo se realizará hoy. El próximo tendrá lugar el ",
        paymentDay_01: "Se le cobrará cada",
        paymentDay_02: "de cada mes.",
        paymentDayErrorRequired: "Por favor, introduzca su primer día de recogida.",
        paymentDayErrorDay: "Por favor, introduzca un día entre 1 y {0}.",
        paymentDayAria: "Día de extracción"
      }
    },
    nextStep: "Siguiente",
    previousStep: "Anterior",
    order: "Comandar",
    report: "Denunciar la página",
    requiredFields: "* Campos obligatorios",
    requiredFieldsLowerCase: "campos obligatorios",
    state: {
      button: "Acceder a mis ajustes",
      deleted: {
        subtitle: "Para restaurarla, vaya a los ajustes de su campaña.",
        title: "¡Esta campaña ha sido eliminada!"
      },
      disabled: {
        subtitle: "Para volver a activarla, vaya a la configuración de su campaña.",
        title: "¡Esta campaña está desactivada!"
      },
      draft: {
        subtitle: "Para publicarla, vaya a la configuración de su campaña.",
        title: "¡Esta campaña está en borrador!"
      }
    },
    step: {
      blockedBanner: {
        paymentHasBeenSuspended: "El pago o pedido en esta campaña está temporalmente suspendido.",
        giveUsEmailToBeNotified: "Indíquenos su correo electrónico para ser notificado de su próxima reapertura. ¡La asociación {0} se pondrá en contacto con usted pronto!"
      },
      vente: {
        noVatRate: "Compra no sujeta a IVA",
        lastPayment: "Para validar su tarjeta bancaria, su primer cobro se realizará hoy. Su último cobro tendrá lugar el {0}.",
        paymentDay_01: "Se le cobrará todos los",
        paymentDay_02: "de cada mes.",
        vatRate: "Compra sujeta a IVA ({0} %)",
        amount: "Importe total (con impuestos) a pagar",
        freeAmount: "Indique el monto a pagar"
      },
      participants: {
        extraOptions: "Opciones adicionales",
        furtherInformations: "Información complementaria",
        participant: "Participante",
        participantProfile: "Perfil del participante",
        vat: "IVA",
        type: {
          shop: {
            participant: "Comprador",
            participantProfile: "Perfil del comprador",
            action: "Comprar"
          },
          event: {
            participant: "Participante",
            participantProfile: "Perfil del participante",
            action: "Participar"
          },
          membership: {
            participant: "Adherente",
            participantProfile: "Coordenadas",
            action: "Adherir"
          },
          crowdfunding: {
            participant: "Contribuyente",
            action: "Contribuir"
          },
          donation: {
            participant: "Contribuyente",
            action: "Contribuir"
          },
          paymentform: {
            participant: "Pagador",
            action: "Pagar"
          }
        }
      },
      payer: {
        existingAccount: "Ya tengo una cuenta en HelloAsso:",
        furtherInformations: "Información adicional",
        login: "me conecto",
        logout: "Modificar la dirección de correo electrónico",
        finalizeAsOrg: "Finalizar como organismo",
        payAsOrg: "Pagar como organismo",
        title: "Información de pago",
        titleFree: "Verifique sus datos"
      },
      summary: {
        acceptDocument: "Acepto el siguiente documento :",
        acceptGdpr_1: "Acepto los",
        acceptGdpr_2: "del servicio y he leído la",
        acceptGdprLink_1: "Términos y Condiciones Generales de Uso",
        acceptGdprLink_2: "carta de privacidad",
        contributingToHelloAsso: "Su contribución a HelloAsso",
        discount: "Reducción",
        donationTitle: "Donación",
        donationCrowd: "Don",
        donation: "Donación adicional a {0}",
        frequency: {
          installment: "Pago en {0} plazos",
          monthly: "Pago mensual"
        },
        product: "Productos",
        membership: "Adhesiones",
        options: "Opciones",
        option: "Opción",
        payment: "Importe (IVA incluido)",
        total: {
          donation: "Don",
          options: "Opciones",
          reduction: "Reducción",
          title: "Total",
          type: {
            crowdfunding: "Contribuciones",
            event: "Boletos",
            membership: "Adhesiones",
            shop: "Productos"
          }
        },
        priceFixed: "Precio definido",
        priceFree: "Precio libre",
        schedulerHelp: "Sus opciones se deben ajustar en su primer vencimiento. Su descuento, si tiene alguno, se aplica a sus vencimientos. La contribución voluntaria que decida dar a HelloAsso es la misma para cada uno de los vencimientos.",
        titleBill: "Resumen",
        titleScheduler: "Sus plazos",
        understandContribution: "Entendí que HelloAsso es una empresa social y solidaria y confirmo que deseo hacerle una contribución financiera, opcional y modificable, al finalizar mi pago para garantizar la gratuidad del servicio a la asociación.",
        totalContribution: "(Sea un total de {0} €)",
        byTerms: "por vencimiento",
        type: {
          crowdfunding: {
            options: "Contribución"
          },
          shop: {
            options: "Productos y opciones"
          },
          event: {
            options: "Boletos y opciones"
          },
          membership: {
            options: "Adhesiones y opciones"
          },
          paymentform: {
            options: "Importe de la venta"
          }
        },
        updateContribution: "Modificar",
        whyContribute: "¿Por qué?",
        whereGoesMyContribution: "¿Adónde va mi contribución?",
        whyContributeHidden: "contribuir al funcionamiento de HelloAsso",
        updateContributionHidden: "su contribución al funcionamiento de HelloAsso"
      },
      ticketingSelect: {
        free: "Gratuito",
        freePrice: "Precio Libre",
        from: "Desde {0} €",
        yourCrowdFundingItemWillCostOnly: "Su don solo le costará {0}€ después de la deducción fiscal",
        yourEventItemWillCostOnly: "Su billete solo le costará {0}€ después de la reducción fiscal",
        yourShopFundingItemWillCostOnly: "Su compra solo le costará {0}€ después de la reducción fiscal.",
        yourMembershipItemWillCostOnly: "Su membresía solo le costará {0}€ después de la deducción de impuestos",
        maxPerUser: "Límite de {0} por persona",
        remainingCrowdFundingItems: "No queda ninguna contraprestación disponible | 1 contraprestación restante | {remaining} contraprestaciones restantes",
        remainingEventItems: "No queda más lugar disponible | 1 lugar restante | {remaining} lugares restantes",
        remainingMembershipItems: "No queda ningún lugar disponible | 1 lugar restante | {remaining} lugares restantes",
        remainingShopItems: "No queda producto disponible | 1 producto restante | {remaining} productos restantes",
        totalRemainingErrorCrowdFundingItems: "No queda ningún monto disponible para la recolección | La recolección está limitada a 1 monto | La recolección está limitada a {total} montos",
        totalRemainingErrorEventItems: "No quedan más plazas disponibles para el evento | El evento está limitado a 1 plaza | El evento está limitado a {total} plazas",
        totalRemainingErrorMembershipItems: "Ya no hay plazas disponibles para la membresía | La membresía está limitada a 1 plaza | La membresía está limitada a {total} plazas",
        totalRemainingErrorShopItems: "No queda producto disponible para la tienda | La tienda está limitada a 1 producto | La tienda está limitada a {total} productos",
        soldout: "Agotado",
        tooMuchTickets: "No hay más lugares disponibles | Solo queda 1 lugar disponible | Solo quedan {remainingEntries} lugares disponibles",
        vatRateEvent: "Inscripción sujeta a IVA ({0} %)",
        vatRateMembership: "Inscripción sujeta a IVA ({0} %)",
        vatRateShop: "Compra sujeta a IVA ({0} %)",
        installment: "Por vencimiento",
        monthly: "Por mes",
        paymentFrequencyTotal: "(Es decir, {0} € en total)",
        scheduledPaymentTotal: "(Es decir, {0} € en total)",
        contribution: "Contribución",
        extraOptionIsSoldOut: "Esa tarifa no está disponible porque al menos una opción obligatoria asociada está actualmente agotada.",
        isFull: "Se trata de la última tarifa disponible porque las opciones obligatorias asociadas están agotadas.",
        maxTiersQuantityReachedPerUser: "Se ha alcanzado el número máximo de tarifas pagaderas de una sola vez."
      }
    },
    stepName: {
      donationSelection: "Dones",
      paymentForm: "Venta",
      membershipSelection: "Elección de membresía",
      members: "Adherentes",
      billingInformation: "Coordenadas",
      summary: "Resumen",
      participants: "Participantes",
      contributors: "Contribuyentes",
      buyers: "Compradores",
      productsDetails: "Detalles de los productos",
      ticketSelection: "Elección de boletos",
      productSelection: "Elección de productos",
      accessibilityBackTo: "Regreso al paso",
      type: {
        membership: {
          members: "Adherentes",
          membershipSelection: "Elección de membresía"
        },
        paymentForm: "Elección de la cantidad"
      }
    },
    validate: "Validar",
    validateAndPay: "Pagar",
    ticketsAmount: "Importe de los billetes:",
    requiredFieldsTextInfo: "Los campos seguidos de un asterisco (*) son obligatorios.",
    openDetail: "Ver el detalle",
    hideDetail: "Ocultar el detalle",
  },
  campaignHeader: {
    by: "por",
    inFavour: "en beneficio de",
    displayMore: "Ver la descripción completa",
    displayLess: "Mostrar menos"
  },
  card: {
    memo: {
      amount: "Total pedido",
      contribute: "Aportación a HelloAsso",
      contributeExplanation: {
        text1: "Nosotros le propondremos",
        text2: "una contribución libre y opcional antes de su pago.",
        text3: "Al elegir contribuir al funcionamiento solidario de HelloAsso, nos permite ofrecer nuestros servicios de forma completamente gratuita para {0} y más de 300,000 asociaciones francesas."
      },
      contributeAmount: "cantidad libre",
      discount: "Reducción",
      donation: "Don",
      free: "Gratis",
      options: "Opción | Opción x1 | Opciones x{nbOptions}",
      person: "persona | personas",
      title: "Detalles de su pedido",
      type: {
        shop: {
          items: "Producto | Producto x1 | Productos x{nbTickets}"
        },
        crowdfunding: {
          items: "Don | Don x1 | Donaciones x{nbTickets}"
        },
        event: {
          items: "Boleto | Boleto x1 | Boletos x{nbTickets}"
        },
        membership: {
          items: "Membresía | Membresía x1 | Membresías x{nbTickets}"
        }
      }
    },
    organization: {
      contact: {
        email: "Correo electrónico",
        phone: "Teléfono",
        showContact: "Mostrar las coordenadas",
        title_1: "¿Una pregunta?",
        title_2: "Contacte a la asociación:"
      },
      eventLink: "Evento en línea",
      map: "Tarjeta"
    },
    time: {
      days: "día | días",
      daysLeft: "día restante | días restantes",
      hours: "hora | horas",
      hoursLeft: "hora restante | horas restantes",
      minutes: "minuto | minutos",
      minutesLeft: "minuto restante | minutos restantes",
      ended: "La recolección ha terminado"
    },
    security: {
      text: "Todos los datos bancarios para procesar este pago son totalmente seguros. Gracias al cifrado SSL de sus datos bancarios, se le garantiza la fiabilidad de sus transacciones en HelloAsso.",
      title: "Plataforma de pago 100% segura"
    },
    seo: "Sobre el mismo tema",
    socials: {
      title: {
        campaign: "Compartir",
        collect: "Compartir la recogida",
        event: "Compartir el evento",
        page: "Compartir esta página",
        shop: "Compartir la tienda"
      }
    }
  },
  checkout: {
    backLabel: "Volver al sitio de {0}",
    footer: {
      conditions: "Condiciones Generales de Uso",
      confidentiality: "Confidencialidad",
      poweredBy: "Creado y difundido con"
    },
    infos: {
      company: "Razón social",
      email: "Correo electrónico",
      firstName: "Nombre",
      lastName: "Nombre",
      payAsOrg: "Pagar como organismo",
      title: "Información del pagador"
    },
    pay: "Pagar {0} €",
    paymentNumber: "En {0} veces",
    scheduler: {
      contribution: "No contribuyas a HelloAsso",
      for: "Para {0}",
      date: "El {0}",
      showDetails: "Mostrar",
      hideDetails: "Esconder",
      ofPayments: "el detalle de los vencimientos",
      title: "Sus plazos de adeudo",
      today: "Hoy",
      total: "Total"
    },
    securedPayment: "Pago seguro",
    security: {
      text: "Todos los pagos utilizando la solución HelloAsso son seguros. La información bancaria para procesar las transacciones está protegida gracias al cifrado SSL.",
      title: "HelloAsso asegura su pago"
    },
    summary: {
      aboutContribution: "HelloAsso funciona gracias a la generosidad de personas como usted. Su contribución voluntaria de {0} € es nuestra única fuente de ingresos y nos permite proporcionar de manera gratuita nuestros servicios a más asociaciones como {1}.",
      acceptGdpr_1: "Acepto los",
      acceptGdpr_2: "del servicio y he leído la",
      acceptGdprLink_1: "Términos y Condiciones de Uso",
      acceptGdprLink_2: "carta de privacidad",
      contribution: "Su contribución al modelo solidario de HelloAsso",
      eachPayment: "Aplicada a cada plazo",
      paymentSolution: "Al elegir nuestra solución solidaria, {0} no paga ninguna comisión ni gastos bancarios en sus transacciones.",
      paymentTo: "Reglamento en {0}",
      title: "A regular hoy",
      total: "Total",
      understandContribution: "Entendí que HelloAsso es una empresa social y solidaria y confirmo que quiero hacerle una contribución financiera, opcional y modificable, al finalizar mi pago para asegurar la gratuidad del servicio a la asociación.",
      updateContribution: "Modificar la contribución voluntaria",
      updateContributionLabel: "la contribución voluntaria"
    },
    title: "Su reglamento",
    pageTitle: "Reglamento en {0} | Helloasso"
  },
  contributionNotice: {
    text_1: "HelloAsso es una empresa social y solidaria, que proporciona gratuitamente sus tecnologías de pago a la organización.",
    text_2: ". Se le propondrá una contribución al funcionamiento de HelloAsso, modificable y opcional, antes de la validación de su pago."
  },
  coupon: {
    invalid: "Código inválido",
    question: "¿Tiene un código promocional?",
    update: "Modificar el código promocional",
    valid: "Código válido",
    label: "Código promocional"
  },
  error: {
    cart: {
      ticketsSoldOut: {
        title: "Demasiado tarde, algunas entradas ya han sido compradas.",
        body: "Gracias por hacer su elección entre los asientos disponibles restantes."
      },
      optionsSoldOut: {
        title: "Demasiado tarde, algunas opciones ya no están disponibles.",
        body: "Gracias por elegir entre las opciones disponibles restantes."
      },
      minimumCart: {
        title: "Cesta mínima no alcanzada",
        body: "Por favor, modifique el importe de su cesta. Debe ser gratuito o superior a 0,50 € para autorizar su pago."
      },
      ticketsHasChanged: {
        title: "Tarifa no reconocida",
        body: "El monto ha sido actualizado. Esta página se va a recargar."
      },
      update: {
        title: "Se ha producido un error.",
        body: "La información no pudo ser actualizada"
      }
    },
    code: {
      400: {
        details: "Tuvimos que recibir una alerta gracias a ti y estamos resolviendo el problema.",
        message: "La solicitud es inválida.",
        title: "¡Nuestras disculpas!"
      },
      401: {
        details: "Tuvimos que recibir una alerta gracias a usted y estamos resolviendo el problema.",
        message: "La solicitud es inválida.",
        title: "¡Nuestras disculpas!"
      },
      403: {
        details: "Tuvimos que recibir una alerta gracias a usted y estamos resolviendo el problema.",
        message: "Usted no tiene los derechos necesarios para acceder a esta página.",
        title: "¡Nuestras disculpas!"
      },
      404: {
        details: "Lo siento, la página a la que quiere acceder no se puede encontrar. Es probable que el enlace esté roto o que la página ya no exista.",
        message: "El enlace está roto o la página ha sido eliminada.",
        title: "Página no encontrada"
      },
      408: {
        details: "La operación parece estar en conflicto, por favor inténtelo de nuevo más tarde.",
        message: "Nuestro servidor ha devuelto un error.",
        title: "¡Nuestras disculpas!"
      },
      409: {
        details: "La operación parece estar en conflicto, por favor inténtelo de nuevo más tarde.",
        message: "Nuestro servidor devolvió un error.",
        title: "¡Nuestras disculpas!"
      },
      413: {
        details: "Se produjo un error al transferir su archivo a nuestro servidor.",
        message: "Se produjo un error al transferir su archivo a nuestro servidor.",
        title: "El archivo es demasiado grande."
      },
      415: {
        details: "Se produjo un error al transferir su archivo a nuestro servidor.",
        message: "Se produjo un error al transferir su archivo a nuestro servidor.",
        title: "El archivo no tiene el formato correcto"
      },
      421: {
        message: "No pudimos enviar su pedido al servidor",
        title: "¡Nuestras disculpas!"
      },
      500: {
        details: "Tuvimos que recibir una alerta gracias a usted y estamos resolviendo el problema.",
        message: "Nuestro servidor devolvió un error.",
        title: "¡Nuestras disculpas!"
      },
      504: {
        details: "El problema encontrado es temporal, ya está siendo resuelto por nuestros equipos técnicos. Le pedimos disculpas por las molestias ocasionadas.",
        subdetails: "Le invitamos a intentar de nuevo en unos momentos para acceder a su página.",
        message: "Nuestro servidor devolvió un error.",
        title: "¡Nuestras disculpas!"
      },
      uncaught: {
        message: "La aplicación encontró un error",
        title: "¡Nuestras disculpas!"
      },
      payment: {
        details: "La operación parece estar en conflicto, por favor intente de nuevo más tarde.",
        message: "Se produjo un error durante el pago.",
        title: "¡Nuestras disculpas!"
      },
      duplicatedPayment: {
        details: "Pago doble.",
        message: "Pago doble.",
        title: "Lamentamos mucho!"
      },
      paymentCanceled: {
        details: "Pago expirado.",
        message: "Pago expirado.",
        title: "¡Nuestras disculpas!"
      },
      expiration: {
        message: "Su cesta ha expirado, por favor inténtelo de nuevo más tarde.",
        title: "¡Nuestras disculpas!"
      },
      ECONNABORTED: {
        message: "La solicitud es demasiado larga y no se pudo completar. Por favor, inténtelo de nuevo.",
        title: "La solicitud ha fallado."
      }
    },
    form: {
      details: "Tuvimos que recibir una alerta gracias a ustedes y estamos resolviendo el problema.",
      message: "Hay errores en el formulario.",
      title: "Atención"
    },
    genericError: "Se produjo un error.",
    page: {
      back: "Regreso",
      backToHome: "Volver a la página de inicio",
      redirection_1: "Puede contactarnos",
      redirection_2: "aquí",
      redirection_3: "acerca de este problema.",
      statusCode: "Código de error: {0}"
    },
    payment: {
      canceled: "El pago ha sido cancelado",
      errorCodes: {
        400: "El carrito es inválido",
        404: "El carrito es desconocido o ha expirado",
        409: "El carrito es inválido",
        gdrpConsentError: "Gracias por aceptar las Condiciones Generales de Uso del servicio.",
        cgvConsentError: "Debe aceptar los Términos y Condiciones: {0}",
        ORGANIZATION_WALLET_ERROR: "Error de validación del organismo",
        tipConsentError: "Gracias por marcar la casilla para confirmar su apoyo financiero a HelloAsso. También puede modificarlo arriba.",
        UNKNOWN: "Se produjo un error desconocido"
      }
    },
    unknown: {
      details: "Tuvimos que recibir una alerta gracias a usted, ¡y estamos resolviendo el problema!",
      message: "Este error nos es desconocido.",
      title: "¡Nuestras disculpas!"
    },
    upload: "Se produjo un error al transferir su archivo a nuestro servidor.",
    uploadedFileTooLarge: "El archivo es demasiado grande.",
    uploadedFileWrongFormat: "El archivo no tiene el formato correcto"
  },
  fiscality: {
    intro: "La donación a {0} da derecho a una reducción fiscal porque cumple con las condiciones generales previstas en los artículos 200 y 238 bis del código general de impuestos.",
    orgSubtitle: "Organismo / Empresa",
    title: "Información fiscal",
    userSubtitle: "Particular"
  },
  contributors: {
    title: "Los contribuyentes",
    moreButton: "Más contribuidores",
    date: "El {0}"
  },
  footer: {
    cta: "Saber más",
    features: {
      alternativeText: "Gracias a un modelo económico que se basa únicamente en la contribución voluntaria de cada uno",
      alternativeTitle: "Alternativo",
      humanText: "Detrás de las líneas de código, hay todo un equipo comprometido con cada usuario.",
      humanTitle: "Humano",
      universalText: "Una solución accesible para la mayoría, fácil de usar",
      universalTitle: "Para todos"
    },
    link: {
      cguOrg: "Asociación CGU",
      cguUser: "Términos y condiciones del usuario",
      confidentiality: "Confidencialidad",
      cookies: "Gestión de cookies",
      guide: "Guía de las Asociaciones",
      legalNotice: "Avisos legales"
    },
    mentions: {
      copyright: "Todos los derechos reservados",
      reassurance: "La plataforma de pago para asociaciones para buscar financiación: membresía, donaciones, crowdfunding, tienda en línea, boletos... ¡Lánzate!",
      lemonway_1: "HelloAsso es una entidad de pago autorizada por la ACPR en Francia el 08/06/2023 con el número 17678Q.",
      lemonway_2: "Nuestra plataforma de pago en línea también está acreditada como ESUS (Empresa Solidaria de Utilidad Social), así como intermediario en financiación participativa ante la ORIAS con el número 17002233.",
      lemonway_3: "HelloAsso es socio de Lemonway, una entidad de pago autorizada por la ACPR en Francia el 24/12/2012 con el número 16568J.",
      fpfMember: "HelloAsso es miembro de la asociación Financement Participatif France",
      regulatedPlatform: "Plataforma regulada por las autoridades francesas"
    },
    missions: {
      aboutHa_1: "HelloAsso es una empresa solidaria de utilidad social. Proporcionamos nuestras tecnologías de pago.",
      aboutHa_2: "gratuitamente, sin costes ni comisiones,",
      aboutHa_3: "a más de",
      aboutHa_4: "200 000 asociaciones francesas.",
      aboutHa_5: "Las contribuciones voluntarias que nos dejan los internautas son nuestra única fuente de ingresos. ¡Gracias por su apoyo!"
    }
  },
  header: {
    accessibilityLabel: "Abrir los ajustes de accesibilidad",
    ariaLabel: "Navegación del sitio",
    menuCloseLabel: "Cerrar el menú",
    menuOpenLabel: "Abrir el menú",
    organizations: {
      add: "Añadir una organización",
      title: "Mis organismos"
    },
    settings: {
      accessibility: "Accesibilidad",
      help: "Ayuda",
      history: "Mi historial",
      signout: "Desconexión",
      profile: "Mi perfil"
    },
    signin: "Mi cuenta",
    signup: "Inscribir mi asociación"
  },
  jumbotron: {
    subtitle: "Para obtener más información, acérquese a la asociación organizadora.",
    title: {
      isCrowdfundingEnded: "La recolección ha terminado!",
      isEventEnded: "¡El evento ha terminado!",
      isMembershipEnded: "¡La fecha límite de inscripción ha pasado!",
      isSaleEventEnded: "¡La fecha límite de inscripción ha pasado!",
      isSaleMembershipEnded: "¡La fecha límite de inscripción ha pasado!",
      isSaleShopEnded: "¡La fecha límite de compra ha pasado!",
      isSaleEventNotYetOpen: "La venta de entradas comienza el {0}",
      isSaleMembershipNotYetOpen: "La apertura de la adhesión comienza el {0}",
      isSaleShopNotYetOpen: "La venta comienza el {0}",
      isSoldOut: "¡Todas las plazas disponibles en línea se han vendido!"
    },
    toOrganismPage: "Ver la página",
    toSettings: "Acceder a mis configuraciones"
  },
  loading: {
    subtitle: "Será redirigido en unos momentos...",
    title: "Gracias por esperar"
  },
  metaTags: {
    event: {
      description: {
        descFirst: "{0} - {1} {2} organiza 1 {3} a {4}",
        descLast: "{0} {1} organiza 1 {2} en {3} - {4}",
        suffix: "{0} - Reserva rápido en línea"
      },
      title: {
        Atelier: "Taller",
        Compétition: "Competencia",
        Cours: "Cursos",
        Concert: "Concierto",
        Festival: "Festival",
        Fête: "Fiesta",
        Spectacle: "Espectáculo",
        Jeu: "Juego",
        Conférence: "Conferencia",
        Culte: "Culto",
        Formation: "Formación",
        Bénévolat: "Voluntariado",
        Séjour: "Estancia",
        Exposition: "Exposición",
        Vente: "Venta",
        Sortie: "Salida"
      },
      descriptionWithCity: "{0} organizado/a por {1} en {2} - {3}",
      descriptionWithoutCity: "{0} organizado/a por {1} - {2}"
    },
    membership: {
      title: {
        Atelier: "Taller",
        Cours: "Curso",
        'Financement de projet': "Financiamiento de proyectos",
        Compétition: "Competición",
        Cotisation: "Cotización",
        Bénévolat: "Voluntariado",
        Formation: "Formación",
        Séjour: "Estancia"
      },
      descriptionWithCity: "{0} organizado/a por {1} en {2} - {3}",
      descriptionWithoutCity: "{0} organizado/a por {1} - {2}"
    },
    payment: {
      finalization: {
        confirmation: "Pago validado",
        pending: "Pago en proceso"
      },
      regularization: {
        confirmation: "Regularización validada",
        failure: "Fallo de la regularización",
        pending: "Regularización en proceso"
      }
    },
    redirect: "Redirección en curso...",
    shop: {
      title: "Venta",
      descriptionWithCity: "{0} organizada por {1} en {2} - {3}",
      descriptionWithoutCity: "{0} organizada por {1} - {2}",
      descriptionMasculinWithCity: "{0} organizado por {1} en {2} - {3}",
      descriptionMasculinWithoutCity: "{0} organizado por {1} - {2}"
    },
    crowdfunding: {
      title: "Financiación participativa",
      descriptionWithCity: "{0} organizada por {1} en {2} - {3}",
      descriptionWithoutCity: "{0} organizada por {1} - {2}",
      descriptionMasculinWithCity: "{0} organizado por {1} en {2} - {3}",
      descriptionMasculinWithoutCity: "{0} organizado por {1} - {2}"
    },
    donation: {
      description: "Hacer una donación a {0} - {1}"
    }
  },
  modal: {
    close: "Cerrar",
    contributionExplication: {
      conclusion: "Todo esto es posible solo gracias a su apoyo.",
      content_1: "Al asegurar nuestro funcionamiento, permite que más de 200,000 asociaciones tengan acceso a una solución de pago adaptada a sus necesidades, sin tarifas ni comisiones.",
      content_2: "Permite emplear a un equipo de más de 100 personas en el que cada uno acompaña a las asociaciones en el desarrollo de sus actividades.",
      content_3: "Hace posible la mejora de nuestros servicios de acompañamiento y de nuestras tecnologías.",
      cta: "Gracias, lo he entendido.",
      intro_1: "Es por esta razón que te necesitamos.",
      intro_2: "¿Para qué sirve su apoyo a HelloAsso?",
      title: "Incluso el pago en línea tiene un costo"
    },
    contributionSelection: {
      noDonationDescription: "Siempre pueden, si lo desean, ayudarnos a su manera, hablando de HelloAsso a su alrededor ❤️",
      newModal: {
        title: "Modificar mi contribución",
        catchPhrase: {
          noDonation: "¡Es nuestra única fuente de ingresos!",
          lowDonation: "Ofreces HelloAsso a {0}",
          midDonation: "Ofrecen HelloAsso a miles de asociaciones",
          highDonation: "Hacen crecer el movimiento del compromiso"
        },
        textImage: {
          noDonation: "Sin su apoyo, no podríamos ofrecer nuestros servicios a más de 300 000 asociaciones. <b>¡Su generosidad marca toda la diferencia!</b>",
          lowDonation: "¡Gracias! Su gesto nos permite seguir siendo 100% gratuitos para {0} (sin tarifas ni comisiones en su pago).",
          midDonation: "¡Muchas gracias! Se unen a los ciudadanos comprometidos que nos permiten crear <b>servicios esenciales y gratuitos para más de 300,000 asociaciones.</b>",
          highDonation: "¡Gracias por apoyar nuestra misión! Gracias a ustedes, tenemos los medios para <b>ayudar a las asociaciones a crecer</b> y a los <b>ciudadanos a comprometerse</b> con ellas."
        },
        contributeUpTo: "su apoyo :",
        validate: "Validar",
        validateHidden: "su contribución y cerrar",
        catchUpTips: {
          noThankYou: "No deseo apoyar hoy",
          addTips: "Dejo una contribución voluntaria de {0} €."
        },
        why: "Comprender mejor el impacto de mi contribución",
        sliderAria: "Seleccione su apoyo a HelloAsso"
      }
    },
    expiredSession: {
      content_1: "Su sesión ha expirado por inactividad.",
      content_2: {
        crowdfunding: "Vaya a la página de inicio de la campaña para continuar con su pago.",
        event: "Regrese a la página de inicio del evento para continuar con su inscripción.",
        membership: "Regrese a la página de inicio de la campaña para continuar con su membresía.",
        shop: "Vuelva a la página de inicio de la tienda para continuar sus compras."
      },
      title: "Sesión expirada"
    },
    gdprDetails: {
      content_1: "Los tratamientos informatizados de estos datos son realizados por la Sociedad HelloAsso en conformidad con el Reglamento General de Protección de Datos (RGPD), que entró en aplicación el 25 de mayo de 2018.",
      content_2: "Para obtener más información sobre el tratamiento de sus datos, le invitamos a consultar nuestra carta de privacidad:",
      content_2_linkLabel: "Hacia la página de Privacidad",
      content_3: "Para cualquier solicitud de ejercicio de sus derechos, puede contactar a nuestro DPO por correo electrónico a",
      content_4: "o enviarle un correo a la dirección de nuestra Sociedad.",
      intro: "Los datos personales que usted proporciona en los formularios de HelloAsso se recopilan con el fin de implementar y realizar los servicios que ofrecemos en nuestra Plataforma:",
      listContent_1: "Crear y gestionar tu perfil de usuario.",
      listContent_2: "Crear y gestionar su acceso a la Plataforma y a los servicios accesibles en línea",
      listContent_3: "Enviar boletines informativos o cualquier mensaje informativo relacionado con nuestras noticias y/o cualquier evolución de nuestros servicios.",
      listContent_4: "Elaborar estadísticas de uso y frecuencia de nuestros servicios",
      listContent_5: "Respetar nuestras obligaciones legales y reglamentarias",
      title: "Datos personales"
    },
    login: {
      connect: "Iniciar sesión",
      email: "Correo electrónico",
      errors: {
        login: {
          details: "La conexión fue rechazada por el servidor.",
          message: "Por favor, verifique su identificación y su contraseña.",
          title: "Se produjo un error."
        }
      },
      forgotPassword: "¿Olvidaste tu contraseña?",
      password: "Contraseña",
      title: "Conexión a HelloAsso"
    },
    report: {
      emailInput: {
        error: "Su correo electrónico debe seguir el formato siguiente: \"juan@ejemplo.fr\".",
        label: "Su correo electrónico",
        placeholder: "Ejemplo: jean@ejemplo.com"
      },
      errorToast: {
        body: "Su informe no pudo ser enviado, por favor intente nuevamente más tarde.",
        title: "Se ha producido un error."
      },
      messageInput: {
        error: "Por favor, indique la razón de su denuncia.",
        label: "Su mensaje",
        placeholder: "La razón de su informe."
      },
      reasonRequired: "Debe seleccionar una razón.",
      reasons: {
        DISLIKE: "No me gusta.",
        INTELLECTUAL_PROPERTY: "Creo que es un uso no autorizado de mi propiedad intelectual.",
        OFF_TOPIC: "Creo que ella no tiene nada que hacer en HelloAsso.",
        SPAM: "Es contenido no deseado"
      },
      successToast: {
        body: "Gracias por su contribución.",
        title: "Informe enviado."
      },
      title: "Denunciar esta página",
      why: "¿Por qué desea reportar esta página?"
    }
  },
  option: {
    required: "(obligatorio)",
    available: "Agotado | 1 disponible | {available} disponibles",
    free: "Gratuito",
    vat: "IVA {0} %"
  },
  optionalDonation: {
    Shop: {
      addDonation_1: "¿Desea añadir una donación a?",
      addDonation_2: "¿además de tus compras?"
    },
    Event: {
      addDonation_1: "¿Desea añadir una donación a",
      addDonation_2: "¿además de su inscripción?"
    },
    Membership: {
      addDonation_1: "¿Desea hacer una donación a",
      addDonation_2: "¿además de su membresía?"
    },
    freeDonationPlaceholder: "Cantidad de su elección",
    noDonation: "Sin donación",
    chooseDonation: "Elegir un monto",
    accessibilityRadio: "Botón para elegir",
    accessibilityRadioCustom: "el monto de su elección"
  },
  organismType: {
    association: "La asociación",
    endowmentFund: "El fondo de dotación",
    foundation: "La fundación"
  },
  payment: {
    aboutContribution: "El modelo HelloAsso garantiza a las asociaciones recibir la totalidad de los pagos como el suyo. No aplicamos ninguna tarifa, ninguna comisión, nuestra única fuente de ingresos no proviene de la publicidad, sino de la generosidad de personas como usted que encuentran útil nuestro servicio. Su apoyo es indispensable y permite a nuestro equipo ayudar aún más a asociaciones de forma gratuita. Gracias a quienes contribuyen a hacer crecer un modelo solidario en la web, y gracias a aquellos a quienes este mensaje podría motivar a actuar.",
    backToCampaign: "Volver a la campaña",
    backToEvent: "Volver a la taquilla",
    backToForm: "Volver al formulario",
    backToShop: "Regresar a la tienda",
    cardNumber: "Número de tarjeta",
    clickOnChosenSocialNetwork: "Haga clic en el icono de la red social elegida",
    confirmationMail: "Recibirá un correo de confirmación en la dirección:",
    cvc: "Criptograma visual",
    cvcDetails: "Los 3 dígitos que se encuentran en la parte trasera de su tarjeta bancaria.",
    dependingOnYourBank: "Según su institución bancaria, es posible que sea redirigido a la página de autenticación de su banco antes de la validación de su pago.",
    downloadDocument: "Descargue sus documentos",
    enterCardInfos: "Por favor, introduzca la información de la tarjeta.",
    error: "No se preocupe, no se ha debitado ninguna cantidad y le invitamos a renovar su pago. Para ayudarle a finalizar su pago, le invitamos a <a href=\"https://centredaide.helloasso.com/particulier?question=comment-resoudre-un-echec-de-paiement-ou-refus-de-paiement\" target=\"_blank\" :title=\"ayuda en línea\">consultar nuestra ayuda en línea</a> o a contactar con su entidad bancaria.",
    errorCode: "Código de error: {0}",
    expirationDate: "Fecha de caducidad",
    fiscalReceipt: "Recibo fiscal",
    getOrders: "Encuentra tus pedidos en cualquier momento en",
    getReceipt: "Sus documentos están disponibles en todo momento en",
    getHelp: "Obtener ayuda",
    goBack: "Volver al paso anterior",
    invitePeople: "Invite a tus seres queridos a unirse a ti:",
    lastStep: "Un último paso para apoyar a {0}, ¡corre la voz!",
    loading: "Cargando...",
    madeBy: "organizado por {0}",
    mandatoryFields: "*campos obligatorios",
    membershipCard: "Tarjeta de socio",
    nextPayment: "Su próximo vencimiento de {0}€ está previsto para el {1}.",
    oneOutOfTwo: "1 de cada 2 personas participa a raíz de una recomendación.",
    order: "Pedido n.º {0}",
    pay: "Pagar {0}€",
    payTerm: "Pagar mi cuota",
    paymentFailed: "El cobro de su vencimiento no se ha realizado.",
    paymentInfos: "Información de pago",
    paymentRefused: "Su solicitud de pago ha sido rechazada.",
    paymentSuccess: "Su pago de {0}€ se ha realizado correctamente.",
    pleaseCheckInfos: "Verifique su saldo o modifique su tarjeta bancaria para volver a intentar su cobro.",
    pleaseTryAgain: "Le invitamos a renovar su pago y a contactar a su entidad bancaria si el problema persiste.",
    paymentReceipt: "Certificado de pago",
    regularizationSuccess: "Su pago de {0}€ ha sido regularizado correctamente.",
    relanchPayment: "Volver a intentar la extracción",
    sharingIsCaring: "Compartir es apoyar: envía este formulario a tus contactos",
    summary: {
      amount: "Importe",
      contribution: "cuya contribución a HelloAsso",
      date: "Deducción del",
      outOf: "{0} de {1}",
      paymentNumber: "Plazo",
      refused: "Rechazado por el banco",
      status: "Estado",
      title: "Resumen:"
    },
    thanks: "Mensaje de {0}:",
    ticket: "Boleto",
    title: {
      confirmation: "Confirmación del pago",
      pending: "Pago",
      regularizationCancellation: "Anulación de la regularización de su vencimiento",
      regularizationConfirmation: "Confirmación de la regularización de su plazo",
      regularizationFailure: "Fallo en la regularización de su vencimiento",
      regularizationPending: "Regularización de su plazo del {0}"
    },
    toHelpCenter: "Hacia el Centro de Ayuda",
    totalAmountContribution: "cuya contribución a HelloAsso",
    totalAmountToPay: "Importe total a pagar",
    updateTickets: "Modificar mi billete",
    useNewPaymentMethod: "En caso de modificación, guarde mi nueva tarjeta para pagar los próximos vencimientos.",
    youCanBeProud: "Invita a tus seres queridos a unirse a ti:",
    youCanHelp: "Puede ayudar a {0} compartiendo su iniciativa {1}.",
    youCanTryAgain: "Si lo desea, puede renovar su intento de pago. En caso de problema, gracias por contactar a su entidad bancaria.",
    yourPaymentHasBeenRefused: "Su intento de pago no ha sido validado.",
    paymentIntentAuthenticationFailure: {
      title: "Su pago no se pudo completar.",
      content: "Este fallo de pago está relacionado con la introducción de sus datos de tarjeta bancaria, su criptograma o su código 3D Secure. Para ayudarle a completar su pago, le invitamos a <a href=\"https://centredaide.helloasso.com/particulier?question=comment-resoudre-un-echec-de-paiement-ou-refus-de-paiement\" target=\"_blank\" title=\"ayuda en línea\">consultar nuestra ayuda en línea</a>. Tranquilo, no se ha debitado ninguna suma tras su intento de pago."
    },
    validatedPayment: {
      title: "Su pago ha sido validado.",
      content: "Recibirá su confirmación de pago y sus documentos por correo electrónico en un plazo de 15 a 60 minutos.  \nSi no recibe la confirmación por correo electrónico, le invitamos a <a href=\"https://www.helloasso.com/contactez-nous\" target=\"_blank\" :title=\"\">contactarnos</a>."
    },
    yourUserspace: "su espacio de usuario.",
    cardUpdate: "El cambio de tarjeta se aplicará a todos los plazos futuros de este pedido.",
    security: "Plataforma de pago 100% segura",
    unpaid: {
      paymentDetail: {
        summary: "Resumen",
        commandNumber: "Pedido n°",
        paymentDate: "Deducción del",
        installment: "Vencimiento",
        amount: "Importe",
        tip: "cuya contribución a HelloAsso",
        status: "Estado",
        totalAmount: "Importe total a pagar",
        on: "sobre",
        refused: "Rechazado",
        showMoreDetail: "Mostrar más detalle"
      }
    },
    apiHaPay: {
      unknownError: "hapay_error_desconocido",
      dbError: "hapay_db_error",
      providerError: "hapay_proveedor_error",
      authenticationError: "hapay_error_de_autenticación"
    },
    modal: {
      waitingRedirectTitle: "Estamos finalizando su pago, esto puede tardar hasta 1 minuto.",
      waitingRedirectContent: "Gracias por no actualizar la página."
    },
    thanksSupport: {
      supporting: {
        title: "Gracias por apoyar a HelloAsso en su misión ❤️",
        paragraph: "Gracias a su contribución voluntaria, no pagaremos ninguna comisión sobre su pago y disfrutamos gratuitamente de las herramientas de pago en línea de HelloAsso. Sus apoyos son su única fuente de ingresos, gracias de su parte."
      },
      notSupporting: {
        title: "Su apoyo a nuestra asociación no tiene precio, pero tiene un costo 💳",
        paragraph: "En otra plataforma, tendríamos que pagar tarifas sobre su pago y no podríamos obtener la totalidad de nuestra recaudación. <br class=\"hideDesktop\" /><br class=\"hideDesktop\"/><strong>Al elegir HelloAsso, nos beneficiamos de un servicio gratuito que nos permite ofrecerle el pago en línea.</strong><br /><br />Todo esto es posible únicamente gracias a las contribuciones voluntarias que puede dejar para apoyar a HelloAsso en su misión. Sin ellas, <strong>42%</strong> de las asociaciones como la nuestra renunciarían al pago en línea por falta de medios."
      }
    },
    HASocials: "Sigan a HelloAsso en Instagram para llenarse de proyectos de impacto, deporte, cultura, naturaleza... 👇",
    pdfFile: "archivo PDF"
  },
  share: {
    buttonLabel: {
      copyUrl: "Copiar la URL",
      copyUrlLabel: "Campo que contiene el enlace de la campaña de la asociación para copiar y compartir",
      copyLink: "el enlace",
      email: {
        campaign: "Compartir la campaña por correo electrónico",
        collect: "Compartir la recopilación por correo electrónico",
        event: "Compartir el evento por correo electrónico",
        page: "Compartir esta página por correo electrónico",
        shop: "Compartir la tienda por correo electrónico",
        name: "Correo electrónico"
      },
      facebook: {
        campaign: "Compartir la campaña en Facebook",
        collect: "Compartir la recopilación en Facebook",
        event: "Compartir el evento en Facebook",
        page: "Compartir esta página en Facebook",
        shop: "Compartir la tienda en Facebook",
        name: "Facebook"
      },
      linkedin: {
        campaign: "Compartir la campaña en LinkedIn",
        collect: "Compartir la colección en LinkedIn",
        event: "Compartir el evento en LinkedIn",
        page: "Compartir esta página en LinkedIn",
        shop: "Compartir la tienda en LinkedIn",
        name: "LinkedIn"
      },
      messenger: {
        campaign: "Compartir la campaña en Messenger",
        collect: "Compartir la colección en Messenger",
        event: "Compartir el evento en Messenger",
        page: "Compartir esta página en Messenger",
        shop: "Compartir la tienda en Messenger",
        name: "Mensajero"
      },
      twitter: {
        campaign: "Compartir la campaña en X",
        collect: "Compartir la colección en X",
        event: "Compartir el evento en X.",
        page: "Compartir esta página en X",
        shop: "Compartir la tienda en X",
        name: "ex Twitter"
      },
      genericShare: {
        name: "Otras aplicaciones"
      },
      whatsapp: {
        name: "WhatsApp",
        campaign: "Compartir la campaña en WhatsApp",
        collect: "Compartir la recogida en WhatsApp",
        event: "Compartir el evento en WhatsApp",
        page: "Compartir esta página en WhatsApp",
        shop: "Compartir la tienda en WhatsApp"
      }
    },
    prefilledText: {
      email: {
        checkout: "🗓️ Finalice ahora su pago a través de nuestro formulario dedicado {0} en HelloAsso",
        crowdfunding: "🗓️ Participe ahora en nuestra campaña {0} en HelloAsso",
        donation: "🗓️ Done ahora a través de nuestro formulario de donación {0} en HelloAsso",
        event: "🗓️ Regístrate ahora para el evento {0} en HelloAsso",
        membership: "🗓️ Únete ahora a nuestra campaña {0} en HelloAsso",
        paymentform: "Paga ahora a través de nuestro formulario dedicado {0} en HelloAsso",
        shop: "🗓️ Compre ahora a través de nuestra tienda {0} en HelloAsso"
      },
      twitter: {
        checkout: "🗓️ Finalice ahora su pago a través de nuestro formulario dedicado {0} : {1} @helloasso",
        crowdfunding: "🗓️ Participe ahora en nuestra campaña {0}: {1} @helloasso",
        donation: "🗓️ Done ahora a través de nuestro formulario de donación {0}: {1} @helloasso",
        event: "🗓️ Inscríbete ahora para el evento {0} : {1} @helloasso",
        membership: "🗓️ Únase ahora a través de nuestra campaña {0} : {1} @helloasso",
        paymentform: "Pague ahora a través de nuestro formulario dedicado {0}: {1} @helloasso",
        shop: "🗓️ Compre ahora en nuestra tienda {0} : {1} @helloasso"
      },
      whatsapp: {
        checkout: "🗓️ Finalice ahora su pago a través de nuestro formulario dedicado {0}: {1}",
        crowdfunding: "🗓️ Participe ahora en nuestra campaña {0}: {1}",
        donation: "🗓️ Done ahora a través de nuestro formulario de donación {0}: {1}",
        event: "🗓️ Regístrese ahora para el evento {0}: {1}",
        membership: "🗓️ Únase ahora a nuestra campaña {0}: {1}",
        paymentform: "🗓️ Pague ahora a través de nuestro formulario dedicado {0}: {1}",
        shop: "🗓️ Compre ahora en nuestra tienda {0}: {1}"
      }
    }
  },
  toast: {
    copyUrl: {
      title: "¡URL copiada! 🎉",
      body: "Ahora puede utilizarlo para compartir su campaña."
    },
    failedRedirection: {
      title: "¡Uf! 😕",
      body: "La redirección al pago falló. Por favor, inténtelo de nuevo."
    },
    usedToken: {
      title: "¡Lo siento! 😧",
      body: "El enlace al que desea acceder ya no es válido o ya ha sido utilizado."
    },
    prospect: {
      title: "Correo electrónico guardado",
      body: "La asociación {0} se pondrá en contacto con usted pronto."
    }
  },
  tooltip: {
    emailConfirmation: "Su dirección de correo electrónico nos permitirá enviarle su correo electrónico de confirmación, así como sus justificantes."
  },
  total: {
    monthlyDonation: "Importe del pago cada mes",
    amount: "Importe a pagar :",
    amountTTC: "Importe IVA incluido :",
    amountWithVAT: "IVA incl.",
    toBePaidToday: "A regular hoy:",
    noCV: "fuera de contribución voluntaria*",
    installments: "Pago en 1x, 3x, 6x, 10x o 12x",
    type: {
      shop: {
        amountTickets: "Importe de las compras:",
        amountTicketsModalities: "Importe IVA incluido :",
        subtitle: "para pagar por tus compras",
        today: "a pagar hoy",
        installmentText1: "luego {0} € las próximas veces",
        installmentText2: "Sean {0} € donados a la asociación {1}",
        installmentText3: "y",
        installmentText4: "€ de ayuda al funcionamiento para HelloAsso"
      },
      event: {
        amountTickets: "Importe de los billetes:"
      },
      membership: {
        amountTickets: "Cantidad a pagar por la duración de la membresía:"
      },
      crowdfunding: {
        amountTickets: "Cantidad a pagar :"
      }
    }
  },
  validators: {
    abnormalEmail: "Su dirección de correo electrónico parece contener caracteres no conformes. Asegúrese de verificarla antes de validar su pago.",
    legalAge: "Los menores no están autorizados a realizar este tipo de pago.",
    youMustChooseAStatus: "Debe elegir una forma jurídica de la lista.",
    optionIsMandatory: "Esta opción es obligatoria",
    date: "Esta fecha no es válida",
    email: "Su dirección de correo electrónico no es válida",
    emailMatch: "La dirección de correo electrónico no coincide con la dirección ingresada en el campo anterior.",
    notPositive: "El valor debe ser positivo.",
    maxAmount: "El valor debe ser un número inferior a {0}€",
    minAmount: "El valor debe ser un número superior a {0}€",
    nameChars: "Su {0} no puede contener ninguno de los siguientes caracteres: , . \\ / * ² & # @ ^ ¨ _ -- + > <",
    nameConsecutiveChars: "Su {0} no debe contener 3 caracteres idénticos consecutivos.",
    nameContainsForbidden: "Su {0} no puede contener firstname, lastname, unknown, first_name, last_name o anónimo.",
    nameDifferent: "Sus apellidos y nombres deben ser diferentes.",
    nameIsForbidden: "Su {0} es inválido.",
    nameLatin: "Su {0} debe escribirse utilizando el alfabeto latino.",
    nameLength: "Su {0} no debe contener un solo carácter.",
    nameNoNumbers: "Su {0} no debe contener números.",
    nameVowels: "Su {0} debe contener al menos una vocal.",
    password: "Su contraseña es demasiado simple.",
    passwordMatch: "Las contraseñas no son idénticas.",
    phone: "Este número de teléfono es inválido",
    required: "Todos los campos obligatorios deben ser completados.",
    requiredAmount: "Por favor, introduzca un importe",
    zipCode: "El código postal es incorrecto.",
    siren: "Su código SIREN debe estar compuesto por 9 dígitos.",
    maxLength: "El campo está limitado a un máximo de {0} caracteres",
    birthdate: "La fecha de nacimiento no es válida."
  },
  widget: {
    form: {
      crowdfunding: "Micromecenazgo",
      event: "Boletería",
      membership: "Adhesión",
      shop: "Tienda",
      paymentform: "Venta",
      donation: "Donación"
    },
    button: {
      widget: "Participar",
      crowdfunding: "Hacer una donación",
      event: "Participar",
      membership: "Adherir",
      shop: "Comprar",
      paymentform: "Hacer un pago",
      donation: "Hacer una donación"
    },
    vignette: {
      participate: "Participo.",
      contribute: "Contribuyo"
    },
    poweredBy: "Creado y distribuido con",
    updateCookies: "Gestión de cookies",
    currencyUnit: "{0}€",
    seeMore: "Ver más"
  },
  cartModalities: {
    title: "Facilidades de pago",
    radio: {
      once: "Pagar el total ahora",
      multiple: "Pagar en varios plazos"
    },
    chooseSuitableModalities: "Elegir las modalidades de pago adecuadas a sus necesidades",
    schedule: "Cronograma",
    today: "Hoy",
    details: "Ver detalles",
    modal: {
      header: "Información sobre su primer pago",
      disclaimer: "Los siguientes elementos deben ser resueltos inmediatamente.",
      options: "Sus opciones",
      donations: "Sus dones",
      donationTo: "Donar a",
      ineligibleTiers: "Sus tarifas",
      eligibleTiers: "Primera fecha límite de las tarifas elegibles"
    },
    totalCart: "Compra total"
  },
  reassurance: {
    securePayment: "Pago seguro"
  },
  voluntaryCard: {
    title: "¿Por qué apoyar a HelloAsso?",
    text1: "Gracias a su apoyo, seguimos siendo 100% gratuitos para",
    text2: "y miles de otras asociaciones. Participe en el éxito de nuestra misión haciendo una contribución en el momento de su pago."
  }
}

import { computed } from 'vue'
import { useStore } from '@nuxtjs/composition-api'
import useRouteParams from '@/composables/useRouteParams'
import { Form, FormCompliance } from '@/components/forms/forms.interface'
import { Tier } from '@/components/tiers/tiers.interface'
import { Organization } from '@/components/organizations/organizations.interface'
import { Cart } from '@/components/carts/carts.interface'
import { UserData } from '~/components/users/users.interface'
import { Payer } from '~/components/payers/payer.interface'

export default () => {
  const store = useStore()
  const { organizationSlug, formSlug, formType, checkoutCartIdParams } = useRouteParams()

  const storeRouteParams = computed(() => ({
    organization: organizationSlug.value,
    slug: formSlug.value,
    type: formType.value?.toUpperCase()
  }))

  const form = computed<Form>(() => store.getters['forms/getForm'](storeRouteParams.value))
  const tiers = computed<Tier[]>(() => form.value.tiers)
  const organization = computed<Organization>(() =>
    store.getters['organizations/getOrganization'](organizationSlug.value)
  )
  const user = computed<UserData>(() => store.getters['user/getMe']())
  const cart = computed<Cart>(() => store.getters['carts/getCart'](storeRouteParams.value))
  const payer = computed<Payer>(() => store.getters['payer/getPrefilledPayer'](cart.value.id))
  const compliance = computed<FormCompliance>(() =>
    store.getters['organizations/getOrganizationCompliance'](organizationSlug.value)
  )
  const canCollect = computed<boolean>(() => compliance.value?.allowCollect !== false)
  const userOrganizations = computed<Organization[]>(() =>
    store.getters['user/getUserOrganizations']()
  )
  const checkoutCart = computed<Cart>(() =>
    store.getters['checkout/getCart'](checkoutCartIdParams.value)
  )

  return {
    form,
    tiers,
    user,
    organization,
    cart,
    payer,
    compliance,
    canCollect,
    storeRouteParams,
    userOrganizations,
    checkoutCart
  }
}

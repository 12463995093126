export default {
  description: {
    customAmount: 'Free Price | Starting at {min} | Starting at {min}',
    defaultTitle: 'Contribution'
  },
  price: {
    perMonth: 'Per month',
    perTime: 'Per installment',
    scheduledTotal: '(That is {total} in total)'
  },
  selector: {
    soldOut: 'Sold Out',
    soldOutLimited: 'Limited',
    labelInput: 'Quantity',
    freeInput: 'Enter free price',
    freeDonationInput: 'Enter free donation'
  },
  Event: {
    maxByTier:
      'No seats available | {max} seat available | {max} seats available',
    maxByForm:
      'The event is limited to {max} seats | {max} seat available | {max} seats available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} seats payable at once has been reached.',
    maxByExtraOption:
      'Only {max} seats left because the associated required options are sold out.',
    vatRate: 'Registration subject to VAT ({vat}%)'
  },
  Membership: {
    maxByTier:
      'No seats available | {max} seat available | {max} seats available',
    maxByForm:
      'Membership is limited to {max} seats | {max} seat available | {max} seats available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} seats payable at once has been reached.',
    maxByExtraOption:
      'Only {max} seats left because the associated required options are sold out.',
    vatRate: 'Membership subject to VAT ({vat}%)'
  },
  Shop: {
    maxByTier:
      'No products available | {max} product available | {max} products available',
    maxByForm:
      'The shop is limited to {max} products | {max} product available | {max} products available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} products payable at once has been reached.',
    maxByExtraOption:
      'Only {max} products left because the associated required options are sold out.',
    vatRate: 'Purchase subject to VAT ({vat}%)'
  },
  CrowdFunding: {
    maxByTier:
      'No rewards available | {max} reward available | {max} rewards available',
    maxByForm:
      'The crowdfunding is limited to {max} rewards | {max} reward available | {max} rewards available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} rewards payable at once has been reached.',
    maxByExtraOption:
      'Only {max} rewards left because the associated required options are sold out.',
    vatRate: 'Reward subject to VAT ({vat}%)'
  },
  Donation: {
    maxByTier: 'No donations available | {max} donation available | {max} donations available',
    maxByForm:
      'The donation campaign is limited to {max} donations | {max} donation available | {max} donations available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} donations payable at once has been reached.',
    maxByExtraOption:
      'Only {max} donations left because the associated required options are sold out.',
    vatRate: 'Donation subject to VAT ({vat}%)'
  },
  PaymentForm: {
    maxByTier:
      'No payments available | {max} payment available | {max} payments available',
    maxByForm:
      'The sale is limited to {max} payments | {max} payment available | {max} payments available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} payments payable at once has been reached.',
    maxByExtraOption:
      'Only {max} payments left because the associated required options are sold out.',
    vatRate: 'Purchase subject to VAT ({vat}%)'
  },
  Checkout: {
    maxByTier:
      'No payments available | {max} payment available | {max} payments available',
    maxByForm:
      'The payment is limited to {max} payments | {max} payment available | {max} payments available',
    maxByUser: 'Limited to {max} per person',
    maxByCart: 'The maximum number of {max} payments payable at once has been reached.',
    maxByExtraOption:
      'Only {max} payments left because the associated required options are sold out.',
    vatRate: 'Purchase subject to VAT ({vat}%)'
  }
}

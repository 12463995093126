// @ts-ignore
import { first, last, filter, toString, toNumber, round, deburr } from 'lodash-es'
import {
  individualPercentageReduction,
  colucheLawIndividualPercentageReductionUnderCeiling,
  colucheLawIndividualCeiling,
  enterprisePercentageReduction
} from '@/constants'
import { haMoment, functions, enum as enums } from '@ha/helpers'
import type { WidgetForm } from '@/components/forms/forms.interface'
import type {
  CartItem,
  CartItemPayload,
  CartUpsertResponse,
  Cart
} from '@/components/carts/carts.interface'
import type {
  CustomField,
  CustomFieldPayload,
  CustomFieldType
} from '@/components/custom-fields/customField.interface'
import type {
  ExtraOption,
  ExtraOptionPlayload
} from '@/components/extra-options/extraOptions.interface'
import type { TipSuggestions } from '@/components/voluntary-contributions/volontaryContribution.interface'
import { ValidationObserver } from 'vee-validate'
import type { NuxtI18nInstance } from '@nuxtjs/i18n'
import { datadogLogs } from '@datadog/browser-logs'

// Format form's activity type
const formatActivityType = (activityType: string) => {
  if (typeof activityType !== 'string') return ''
  return deburr(activityType.split(' ').join('-').toLowerCase())
}

const getYoutubeId = (url: string): string => {
  // eslint-disable-next-line
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = url.match(regExp)

  if (match && match[2].length === 11) {
    return match[2]
  }
  return 'error'
}

const getDateLabel = (
  startDate: string,
  endDate: string,
  i18n: NuxtI18nInstance
): string | null => {
  if (!startDate && !endDate) return null
  let startTime = startDate ? haMoment(startDate).format('HH[h]mm') : null
  let endTime = endDate ? haMoment(endDate).format('HH[h]mm') : null

  const isSameDay = startTime && endTime ? haMoment(startDate).isSame(endDate, 'day') : null

  if (!endTime || endTime === '00h00' || endTime === '23h59') {
    endTime = null
  } else {
    endTime = endTime.replace('h00', 'h')
  }

  if (!startTime || startTime === '00h00' || startTime === '23h59') {
    startTime = null
  } else {
    startTime = startTime.replace('h00', 'h')
  }

  // both dates defined and not the same day
  if (startDate && endDate && !isSameDay) {
    const onTheSameMonth = haMoment(startDate).format('MMMM') === haMoment(endDate).format('MMMM')
    const onTheSameYear = haMoment(startDate).format('YYYY') === haMoment(endDate).format('YYYY')

    let startDateLabel: string = haMoment(startDate).format('DD')
    const endDateLabel: string = haMoment(endDate).format('DD MMMM YYYY')

    // > From 08 April to 12 May 2019
    if (!onTheSameMonth && onTheSameYear) {
      startDateLabel = haMoment(startDate).format('DD MMMM')
    }

    // > From 08 April 2018 to 12 May 2019
    if (!onTheSameYear) {
      startDateLabel = haMoment(startDate).format('DD MMMM YYYY')
    }

    // If the event is over several days with defined time :
    if (startTime && endTime) {
      // > From 08 to 12 May 2019, from 7:30 to 10:00 p.m.
      return i18n.t('campaign.date.with2Dates.with2Times', [
        startDateLabel,
        endDateLabel,
        startTime,
        endTime
      ]) as string
    }

    // If the event has only one start time:
    if (startTime && !endTime) {
      // > [date or not], starting at 7:00 p.m.
      return i18n.t('campaign.date.with2Dates.withStartTime', [
        startDateLabel,
        endDateLabel,
        startTime
      ]) as string
    }

    // If the event has only an end time
    if (!startTime && endTime) {
      // > [date or not], until 10:00 p.m.
      return i18n.t('campaign.date.with2Dates.withEndTime', [
        startDateLabel,
        endDateLabel,
        endTime
      ]) as string
    }

    // If the event is over several days with no time :
    if (!startTime && !endTime) {
      // > From 08 to 12 May 2019
      return i18n.t('campaign.date.with2Dates.withNoTime', [startDateLabel, endDateLabel]) as string
    }
  }

  // If the event is on a day with defined time :
  if (isSameDay && startTime && endTime) {
    // > May 12, 2019, from 7:30 p.m. to 10 p.m.
    return i18n.t('campaign.date.SameDay.with2Times', [
      haMoment(startDate).format('DD MMMM YYYY'),
      startTime,
      endTime
    ]) as string
  }

  // If the event is on a day without time set:
  if (isSameDay && !startTime && !endTime) {
    // > May 12, 2019
    return i18n.t('campaign.date.SameDay.withNoTime', [
      haMoment(startDate).format('DD MMMM YYYY')
    ]) as string
  }

  // If the event is without a defined date but with defined times :
  if (startTime && endTime && !startDate && !endDate) {
    // > From 7:30 p.m. to 10:00 p.m.
    return i18n.t('campaign.date.noDate.with2Times', [startTime, endTime]) as string
  }

  // If the event has only one start time:
  if (isSameDay && startTime && !endTime) {
    // > [date or not], starting at 7:00 p.m.
    return i18n.t('campaign.date.SameDay.withStartTime', [
      haMoment(startDate).format('DD MMMM YYYY'),
      startTime
    ]) as string
  }

  // If the event has only an end time
  if (isSameDay && !startTime && endTime) {
    // > [date or not], until 10:00 p.m.
    return i18n.t('campaign.date.SameDay.withEndTime', [
      haMoment(endDate).format('DD MMMM YYYY'),
      endTime
    ]) as string
  }

  // If no date is entered
  // > no message is displayed under the ticketing title of the purchase path
  return null
}

const getShortDateLabel = (startDate: string, i18n: NuxtI18nInstance): string => {
  return i18n.t('campaign.date.SameDay.withNoTime', [
    haMoment(startDate).format('DD MMMM YYYY')
  ]) as string
}

const getShortTimeLabel = (startDate: string): string | null => {
  let startTime: string | null = startDate ? haMoment(startDate).format('HH[h]mm') : null

  if (startTime === '00h00' || startTime === '23h59') {
    startTime = null
  } else {
    if (startTime) {
      startTime = startTime.replace('h00', 'h')
    }
    return startTime
  }
  return null
}

const getTicketRangePrice = (form: WidgetForm) => {
  const { maxPrice, minPrice } = form

  return minPrice === maxPrice
    ? `${functions.convertToEuros(maxPrice)}€`
    : `${functions.convertToEuros(minPrice)}€ - ${functions.convertToEuros(maxPrice)}€`
}

const getCustomFieldValue = (customField: CustomField) => {
  switch (customField.type) {
    case 'Date':
      return customField.value
        ? (haMoment(customField.value, 'DD/MM/YYYY').format('DD/MM/YYYY') as string)
        : null
    case 'YesNo':
      if (customField.value === 'Oui') return true
      if (customField.value === 'Non') return false
      return null
    default:
      return customField.value
  }
}

const mapDataReturnedByAPI = (cart: CartUpsertResponse): Cart => {
  const itemList: CartItem[] = cart.itemList?.map(cartItem => {
    const tierItem = cart.tierList?.find(tier => tier.id === cartItem.tierId)

    const customFields = tierItem?.customFields?.map<CustomField>(customFieldTemplate => {
      const cartCustomField = cartItem.customFields?.find(
        item => item.fieldId === customFieldTemplate.id
      )

      const mappedCustomField = {
        ...customFieldTemplate,
        ...(cartCustomField || {})
      }

      mappedCustomField.value = getCustomFieldValue(mappedCustomField)
      return mappedCustomField
    })

    const extraOptions = tierItem?.extraOptions?.map<ExtraOption>(
      (extraOptionTemplate: ExtraOption) => {
        const cartExtraOption = cartItem.extraOptions?.find(
          item => item.extraOptionId === extraOptionTemplate.id
        )

        const customFields = extraOptionTemplate.customFields?.map(
          (extraOptionCustomField: CustomField) => {
            const cartCustomField = cartExtraOption?.customFields?.find(
              item => item.fieldId === extraOptionCustomField.id
            )

            const mappedExtraOptionCustomField = {
              ...extraOptionCustomField,
              ...(cartCustomField || {})
            }

            mappedExtraOptionCustomField.value = getCustomFieldValue(mappedExtraOptionCustomField)
            return mappedExtraOptionCustomField
          }
        )

        const mappedExtraOption = {
          ...extraOptionTemplate,
          ...(cartExtraOption || {}),
          value: !!cartExtraOption?.extraOptionId,
          customFields
        }

        return mappedExtraOption
      }
    )

    return {
      ...cartItem,
      customFields: customFields ?? [],
      extraOptions: extraOptions ?? []
    }
  })

  return { ...cart, itemList }
}

const extractFieldValue = ({ value, type }: CustomField) => {
  return type === 'Date' && value ? haMoment(value, 'DD/MM/YYYY').format() : toString(value)
}

const extractCustomFields = (customFields: CustomField[] = []): CustomFieldPayload[] => {
  // replace value by value for each custom fields
  // Refactor - Why remove all other properties of customField ?
  return (
    customFields
      .map(customField => ({
        fieldId: customField.id,
        value: extractFieldValue(customField)
      }))
      // filter empty string (API return errors otherwise)
      .filter(({ value }) => value !== '')
  )
}

const extractExtraOptions = (extraOptions: ExtraOption[] = []): ExtraOptionPlayload[] => {
  // Refactor - Why remove all other properties of extraOption ?
  return extraOptions
    .map(({ id: extraOptionId, customFields: optionFields, value }) => ({
      extraOptionId,
      value,
      customFields: extractCustomFields(optionFields)
    }))
    .filter(opt => opt.value === true)
}

/**
 * Map cart data sent to API in a POST / PUT cart
 * @param {*} payload
 */
const mapDataSentToApi = (payload: CartItem[]): CartItemPayload[] => {
  return payload.map(item => ({
    ...item,
    customFields: extractCustomFields(item.customFields),
    extraOptions: extractExtraOptions(item.extraOptions)
  }))
}

const getFormUrl = (orgSlug: string, formType: string, formSlug: string) => {
  return `${process.env.NUXT_ENV_BASE_URL}/associations/${orgSlug}/${formType}/${formSlug}`
}

const getFormTypeForUrl = (type: string) => {
  switch (type) {
    case enums.FormType.CROWDFUNDING:
      return 'collectes'
    case enums.FormType.DONATION:
      return 'formulaires'
    case enums.FormType.EVENT:
      return 'evenements'
    case enums.FormType.MEMBERSHIP:
      return 'adhesions'
    case enums.FormType.PAYMENTFORM:
      return 'paiements'
    case enums.FormType.SHOP:
      return 'boutiques'
    default:
      return null
  }
}

const computeRfReduction = (
  totalAmount: number,
  isUnderColucheLaw: boolean,
  isEnterprise: boolean
): number => {
  let reduction = 0

  if (isEnterprise) {
    reduction = (totalAmount * enterprisePercentageReduction) / 100
  } else if (isUnderColucheLaw) {
    const tempCeilingValue = Math.min(totalAmount, colucheLawIndividualCeiling)
    reduction =
      (tempCeilingValue * colucheLawIndividualPercentageReductionUnderCeiling) / 100 +
      ((totalAmount - tempCeilingValue) * individualPercentageReduction) / 100
  } else {
    reduction = (totalAmount * individualPercentageReduction) / 100
  }

  return round(reduction, 2)
}

// Refactor - this function never worked
const checkNumericEntry = (event: KeyboardEvent) => {
  const target = event.target as HTMLInputElement
  if (
    (Number.isNaN(event.key) &&
      ![
        'Tab',
        'Backspace',
        'Delete',
        'Home',
        'End',
        'ArrowUp',
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        ',',
        '.'
      ].includes(event.key)) ||
    (event.key === 'ArrowDown' && toNumber(target.value) === 0) ||
    (event.key === '.' && (target.value.match(/\./g) || []).length > 0)
  ) {
    event.preventDefault()
  }
}

const getValidationProviderRules = (customField: CustomField): string => {
  const validators: string[] = []

  // @TODO : handle "special" customField cases when implemented in API (eg. "email")

  if (customField?.isRequired) {
    validators.push('REQUIRED')
  }

  if (customField?.type === 'Phone') {
    validators.push('PHONE')
  }

  if (customField?.type === 'Zipcode') {
    validators.push('ZIP_CODE')
  }

  if (customField?.type === 'Date') {
    validators.push('DATE')
  }

  return validators.join('|')
}

/**
 * Get validator provider's mode of field
 * @param {string} fieldType
 * @param {Boolean} isAppleDevice
 * @return {String} validator provider's mode
 */
const getValidationProviderMode = (
  CustomFieldType: CustomFieldType,
  isAppleDevice: boolean
): string => {
  if (isAppleDevice) return 'aggressive'
  return ['Date', 'Phone'].includes(CustomFieldType) ? 'lazy' : 'aggressive'
}

/**
 * Scroll to the first field in error in a given form
 * In this method we use a formRef with nested form fields refs,
 * this fields refs can be automaticaly added by Vee-Validate Validation Provider.
 * @param {object} formRef
 */
const scrollCenterToErrorElement = (formRef: InstanceType<typeof ValidationObserver>) => {
  if (!formRef.errors) return
  const firstErrorIndex = Object.values(formRef.errors).findIndex(el => el.length > 0)
  if (firstErrorIndex === -1) return
  const elementRefKey = Object.keys(formRef.errors)[firstErrorIndex]
  if (formRef.refs[elementRefKey]?.$el) {
    formRef.refs[elementRefKey].$el.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth'
    })
  } else if (formRef.observers[0].refs[elementRefKey]?.$el) {
    // we use validationObserver for payerPersonalInformations
    formRef.observers[0].refs[elementRefKey].$el.scrollIntoView({
      block: 'center',
      inline: 'center',
      behavior: 'smooth'
    })
  }
}

/**
 * Get clean string of number value from input
 */
const cleanInputStringNumber = (value: string): string => {
  if (!['string', 'number'].includes(typeof value)) return ''

  const cleanValue = value.toString().replace(',', '.')
  const numberDecimal = cleanValue.split('.')[1]?.length || 0
  const parseValue = parseFloat(cleanValue)
  if (Number.isNaN(parseValue)) return ''
  if (numberDecimal > 2) return parseValue.toFixed(2)
  if (parseValue >= 1 && cleanValue.startsWith('0')) return cleanValue.substring(1)
  return cleanValue
}

const locationRedirectOnce = async (redirect: string, prefixKey = 'redirect-') => {
  // Check if context is client side
  if (typeof window === 'undefined') {
    return
  }
  // Ensure prefixKey + redirect
  const key = `${prefixKey}${redirect}`
  // Check if key is already setted in session storage
  if (window.sessionStorage.getItem(key) === '1') {
    datadogLogs.logger.info(`locationRedirectOnce ${key} was already called, form ${window.location.href} to ${redirect}`)
    return
  }
  // Set key to session storage
  window.sessionStorage.setItem(key, '1')
  // Redirect
  window.location.href = redirect
}

export {
  locationRedirectOnce,
  getYoutubeId,
  getDateLabel,
  getShortDateLabel,
  getShortTimeLabel,
  getTicketRangePrice,
  getFormUrl,
  getFormTypeForUrl,
  mapDataReturnedByAPI,
  mapDataSentToApi,
  computeRfReduction,
  checkNumericEntry,
  getValidationProviderRules,
  getValidationProviderMode,
  formatActivityType,
  cleanInputStringNumber,
  scrollCenterToErrorElement
}

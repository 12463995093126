import { unset } from 'lodash-es'
import { mapDataReturnedByAPI } from '@/helpers/formUtils'
import type { Payer } from '~/components/payers/payer.interface'
import { ActionTree, GetterTree, MutationTree } from 'vuex/types/index'
import { AxiosInstance } from 'axios'
import type { Checkout, Tips } from '~/components/checkouts/checkouts.interface'
import { FormsStoreRootState } from '~/types/common.interface'

export const state = () => ({
  checkouts: {} as Checkout,
  tips: {} as Tips
})

export type CheckoutState = ReturnType<typeof state>

export const mutations: MutationTree<CheckoutState> = {
  SET_CART(state, [cart, cartId]) {
    Object.assign(state.checkouts, {
      [cartId]: {
        ...cart
      }
    })
  },
  SET_TIP(state, [tip, cartId]) {
    Object.assign(state.tips, {
      [cartId]: {
        tip
      }
    })
    state.tips = { ...state.tips }
  },
  UNSET_TIP(state, cartId) {
    unset(state.tips, [cartId, 'tip'])
  }
}

export const getters: GetterTree<CheckoutState, FormsStoreRootState> = {
  getCart: state => (cartId: string) => {
    return state.checkouts[cartId]
  },
  getTip: state => (cartId: string) => {
    return state.tips[cartId].tip ?? 0
  },
  getDiscount: state => (cartId: string) => {
    const result = state.checkouts[cartId]
    return result?.paymentTerms?.[0]?.discount ?? 0
  },
  getRealAmount: state => (cartId: string) => {
    const result = state.checkouts[cartId]
    return result?.paymentTerms?.[0]?.realAmount ?? 0
  }
}

export const actions: ActionTree<CheckoutState, FormsStoreRootState> = {
  getCart({ commit }, { cartId, cartKey, organizationSlug }) {
    /* cartKey is a URL queryParam used to prevent request forgery only for checkout */
    const headers = cartKey
      ? {
          'X-HA-Cart-Key': cartKey
        }
      : {}

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .get(
        `/carts/${cartId}?organizationSlug=${organizationSlug}&formType=checkout&formSlug=default`,
        {
          headers
        }
      )
      .then(response => {
        commit('SET_CART', [response.data, cartId])
        return response.data
      })
  },

  putTip({ commit }, [tip, id]: [number, string]) {
    const tipPayload = {
      tip: Number(tip).toFixed() // remove any decimals that can appear from float operations
    }

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance).put(`/carts/${id}/tip`, tipPayload).then(response => {
      const data = mapDataReturnedByAPI(response.data)
      commit('SET_CART', [data, id])
      return data
    })
  },

  // eslint-disable-next-line no-empty-pattern
  putPayer(_, [payer, cartId]: [Payer, string]) {
    // For testing purpose only => to be removed when api will have this parameter optionnal
    console.warn('[consent]: forced to true for testing purpose')
    const updatePayer: any = { ...payer }
    updatePayer.payer.consent = true

    // @ts-expect-error import problem
    return (this.$apiClient as AxiosInstance)
      .put(`/carts/${cartId}/payer`, updatePayer)
      .then(response => response.data)
      .catch(e => {
        throw e
      })
  }
}

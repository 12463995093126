export default {
    today: "Today",
    other: "other",
    price: "{price}€",
    free: "Free",
    Event: {
      tierName: "seat | seats"
    },
    Membership: {
      tierName: "seat | seats"
    },
    Shop: {
      tierName: "product | products"
    },
    CrowdFunding: {
      tierName: "reward | rewards"
    },
    Donation: {
      tierName: "donation | donations"
    },
    PaymentForm: {
      tierName: "payment | payments"
    },
    Checkout: {
      tierName: "payment | payments"
    }
  }
  
import type { Middleware } from '@nuxt/types'
import { functions } from '@ha/helpers'

const urlNormalization: Middleware = ({ params, route, redirect }) => {
  if (
    route.path === '/error' ||
    route.path === '/redirection' ||
    route.path.startsWith('/paiement/') ||
    route.path.startsWith('/ha-api/') ||
    route.path.startsWith('/ha-oauth/') ||
    route.path.startsWith('/forms/')
  ) {
    return
  }

  // Normalize (and sanitize) URL with global common rules.
  const normalizedPath: string = functions.getNormalizedUrl(route.path)

  if (normalizedPath !== route.path) {
    redirect(301, { path: normalizedPath })
  }

  const { organizationSlug, formType, campaignSlug, steps, cartId } = params

  if (organizationSlug && formType) {
    if (formType === 'formulaires' && !campaignSlug) {
      redirect(301, {
        path: `/associations/${organizationSlug}/${formType}`
      })
    }

    if (formType === 'paiements' && steps) {
      redirect(301, {
        path: `/associations/${organizationSlug}/${formType}/${campaignSlug}`
      })
    }

    if (steps && !['1', '2', '3', '4'].includes(steps)) {
      redirect(301, {
        path: `/associations/${organizationSlug}/${formType}/${campaignSlug}`
      })
    }
  }

  if (
    !(organizationSlug || formType || campaignSlug || steps) &&
    !(route.path.endsWith(cartId) || route.path.endsWith(cartId + '/'))
  ) {
    redirect(301, {
      path: '/e/recherche'
    })
  }
}

export default urlNormalization

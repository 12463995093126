export default {
  today: "Hoy",
  other: "otro",
  price: "{price}€",
  free: "Gratis",
  Event: {
    tierName: "plaza | plazas"
  },
  Membership: {
    tierName: "plaza | plazas"
  },
  Shop: {
    tierName: "producto | productos"
  },
  CrowdFunding: {
    tierName: "contrapartida | contrapartidas"
  },
  Donation: {
    tierName: "donación | donaciones"
  },
  PaymentForm: {
    tierName: "pago | pagos"
  },
  Checkout: {
    tierName: "pago | pagos"
  }
}

import { Plugin } from '@nuxt/types'
import { Tracking } from '@ha/helpers'
import getTrackingRouteData from '@/helpers/getTrackingRouteData'

const segmentPlugin: Plugin = ({ $config: { SEGMENT_KEY, SEGMENT_ENABLED }, app }, inject) => {
  const tracking = new Tracking({
    segmentKey: SEGMENT_KEY,
    enabled: SEGMENT_ENABLED
  })
  const excludedPages = ['/confirmation/', '/redirection', '/embarque']
  app.router?.afterEach(route => {
    if (!excludedPages.some(page => route.path.includes(page))) {
      tracking.page(getTrackingRouteData(route))
    }
  })

  inject('tracking', tracking)
}

export default segmentPlugin

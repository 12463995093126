/* eslint-disable no-underscore-dangle */
let hasAmplitudeConsent = false
function loadAxeptioChoices() {
  // @ts-expect-error _axcb doesn't exist on type Window since it's a custom property from Axeptio
  window._axcb = []
  // @ts-expect-error same as above
  window?._axcb.push(function (axeptio) {
    axeptio.on('cookies:complete', function (choices) {
      if (choices['amplitude-exp-abtest']) {
        hasAmplitudeConsent = true
      }
    })
  })
}
loadAxeptioChoices()

export default (_, inject) => {
  inject('axeptio', {
    hasAmplitudeConsent
  })
}

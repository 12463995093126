export default {
  description: {
    customAmount: 'Precio Libre | A partir de {min} | A partir de {min}',
    defaultTitle: 'Contribución'
  },
  price: {
    perMonth: 'Por mes',
    perTime: 'Por vencimiento',
    scheduledTotal: '(Es decir {total} en total)'
  },
  selector: {
    soldOut: 'Agotado',
    soldOutLimited: 'Limitado',
    labelInput: 'Cantidad',
    freeInput: 'Precio libre para rellenar',
    freeDonationInput: 'Donación libre para rellenar'
  },
  Event: {
    maxByTier:
      'No quedan plazas disponibles | {max} plaza disponible | {max} plazas disponibles',
    maxByForm:
      'El evento está limitado a {max} plazas | {max} plaza disponible | {max} plazas disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} plazas pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} plazas porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Inscripción sujeta a IVA ({vat} %)'
  },
  Membership: {
    maxByTier:
      'No quedan plazas disponibles | {max} plaza disponible | {max} plazas disponibles',
    maxByForm:
      'La membresía está limitada a {max} plazas | {max} plaza disponible | {max} plazas disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} plazas pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} plazas porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Inscripción sujeta a IVA ({vat} %)'
  },
  Shop: {
    maxByTier:
      'No quedan productos disponibles | {max} producto disponible | {max} productos disponibles',
    maxByForm:
      'La tienda está limitada a {max} productos | {max} producto disponible | {max} productos disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} productos pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} productos porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Compra sujeta a IVA ({vat} %)'
  },
  CrowdFunding: {
    maxByTier:
      'No quedan recompensas disponibles | {max} recompensa disponible | {max} recompensas disponibles',
    maxByForm:
      'La recaudación está limitada a {max} recompensas | {max} recompensa disponible | {max} recompensas disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} recompensas pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} recompensas porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Recompensa sujeta a IVA ({vat} %)'
  },
  Donation: {
    maxByTier:
      'No quedan donaciones disponibles | {max} donación disponible | {max} donaciones disponibles',
    maxByForm:
      'La campaña de donación está limitada a {max} donaciones | {max} donación disponible | {max} donaciones disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} donaciones pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} donaciones porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Donación sujeta a IVA ({vat} %)'
  },
  PaymentForm: {
    maxByTier:
      'No quedan pagos disponibles | {max} pago disponible | {max} pagos disponibles',
    maxByForm:
      'La venta está limitada a {max} pagos | {max} pago disponible | {max} pagos disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} pagos pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} pagos porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Compra sujeta a IVA ({vat} %)'
  },
  Checkout: {
    maxByTier:
      'No quedan pagos disponibles | {max} pago disponible | {max} pagos disponibles',
    maxByForm:
      'El pago está limitado a {max} pagos | {max} pago disponible | {max} pagos disponibles',
    maxByUser: 'Limitado a {max} por persona',
    maxByCart: 'Se ha alcanzado el número máximo de {max} pagos pagables a la vez.',
    maxByExtraOption:
      'Solo quedan {max} pagos porque las opciones obligatorias asociadas están agotadas.',
    vatRate: 'Compra sujeta a IVA ({vat} %)'
  }
}

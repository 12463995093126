import { Plugin } from '@nuxt/types'
import Cookies from 'universal-cookie'
import { accessTokenCookieName, refreshTokenCookieName, sessionTokenCookieName } from '~/constants'
import { getCookieOption } from '~/helpers/auth'

const cookiesPlugin: Plugin = ({
  $config: { NUXT_ENV_BASE_URL },
  app,
  req
}, inject) => {
  const { $cookiesServer } = app

  // define cookie config option
  const cookieOption = getCookieOption(NUXT_ENV_BASE_URL)

  // cookie du client venant de la request
  const requestCookieClient = req?.headers?.cookie ?? null

  // reset existing universal-cookie instance OR set a new and empty universal-cookie instance
  const $cookiesClient = new Cookies(requestCookieClient, cookieOption)

  // clear empty or undefined cookies
  const cookiesSnapshot = $cookiesClient.getAll()
  for (const cookieName in cookiesSnapshot) {
    if (Object.hasOwn(cookiesSnapshot, cookieName)) {
      const cookieValue = cookiesSnapshot[cookieName];
      if ((cookieValue === 'undefined' || cookieValue === '')) {
        $cookiesClient.remove(cookieName, cookieOption)
      }
    }
  }

  // force dirty clean
  if (process.client) {
    document.cookie.split(";").forEach(function (cookie) {
      const cookieName = cookie.split('=')[0].trim()
      const cookieValue = cookie.split('=')[1]
      if (
        [accessTokenCookieName, refreshTokenCookieName, sessionTokenCookieName].includes(cookieName)
        && (cookieValue === 'undefined' || cookieValue === '')) {
        document.cookie = cookie
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date(0).toUTCString() + ";path=/");
      }
    });
  }

  // check if access token is present
  const hasCookieClientToken = requestCookieClient?.includes(accessTokenCookieName)

  // Use Server cookie instance if any request cookie is present and if process is server side
  const useCookieServer = process.server && !hasCookieClientToken && typeof $cookiesServer === 'object'

  // choose right cookie instance
  const cookies = useCookieServer ? $cookiesServer : $cookiesClient

  // inject cookies plugin is nuxt context
  inject('cookies', cookies)
}

export default cookiesPlugin